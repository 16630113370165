/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InfoFilled } from '@one-thd/sui-icons';
import {
  Link, Typography, Alert, IconButton, Popover, Icon
} from '@one-thd/sui-atomic-components';
import { ClearancePopoverContent } from '@thd-olt-component-react/price';
import { CLEARANCE_MESSAGE } from '../../../constants';
import { parsePriceValue } from '../../../helper/utils';
import '../../fulfillment-stencil-override.scss';

export const ClearancePickupMessageUnderTile = ({
  isClearanceItem = true, localstore, pricing, isCartTiles, itemId
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const CLEARANCE_POPOVER_MESSAGE = `Visit ${localstore} to 
    purchase this item on Clearance in store. While supplies last. `;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (typeof window !== 'undefined' && isCartTiles && open) {
      const eventObj = {
        primaryCategory: 'tooltip',
        eventName: 'in-store clearance message',
        sku: itemId
      };
      window.LIFE_CYCLE_EVENT_BUS.trigger('clearanceProduct.tooltipEvent', eventObj);
    }
  }, [open]);

  const seeMoreDetailsLink = () => {
    return (
      <>
        <Popover
          open={open}
          id={id}
          anchorEl={anchorEl}
          placement="bottom"
          title="In-Store Clearance Item"
          onClose={handleClose}
          style={{ zIndex: 900 }}
        >
          <ClearancePopoverContent
            message={CLEARANCE_POPOVER_MESSAGE}
            originalPrice={pricing?.original ? pricing?.original : pricing?.value}
            clearanceValue={pricing?.clearance?.value}
            dollarOff={pricing?.clearance?.dollarOff}
            percentageOff={pricing?.clearance?.percentageOff}
            unitsClearancePrice={pricing?.clearance?.unitsClearancePrice}
            caseUnitOfMeasure={pricing?.alternate?.unit?.caseUnitOfMeasure}
            unitsOriginalPrice={pricing?.alternate?.unit?.unitsOriginalPrice || pricing?.alternate?.unit?.value}
            alternatePriceDisplay={pricing?.alternatePriceDisplay}
          />
        </Popover>
        <Typography
          component="span"
          role="button"
          decoration="underline"
          onClick={(event) => handleClick(event)}
        >
          <span className="sui-whitespace-nowrap">
            {CLEARANCE_MESSAGE.partThree}
          </span>
        </Typography>
      </>
    );
  };

  return (
    <div>
      {isClearanceItem && pricing?.clearance
        && (
          <div className="clearance_msg_width">
            <span className="sui-flex">
              <div className="clearance_icon_pt sui-pr-1">
                <InfoFilled
                  size="small"
                  style={{ color: '#3e7697' }}
                />
              </div>
              <div className="">
                <Typography variant="body-base">
                  <span className="sui-z-10">
                    {CLEARANCE_MESSAGE.partOne}{localstore}.
                    {CLEARANCE_MESSAGE.partTwo}{seeMoreDetailsLink()}
                  </span>
                </Typography>
              </div>
            </span>
          </div>
        )}
    </div>
  );
};

ClearancePickupMessageUnderTile.propTypes = {
  isCartTiles: PropTypes.bool,
  isClearanceItem: PropTypes.bool,
  itemId: PropTypes.string,
  localstore: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  pricing: PropTypes.object
};

ClearancePickupMessageUnderTile.defaultProps = {
  isCartTiles: false,
  isClearanceItem: false,
  itemId: '',
  localstore: null,
  pricing: {}
};
