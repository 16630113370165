import React, { useContext, Suspense } from 'react';
import { bool, string, shape, number } from 'prop-types';

import { QueryProvider, extend } from '@thd-nucleus/data-sources';
import { Rating } from '@thd-olt-component-react/core-ui';
import { Price } from '@thd-olt-component-react/price';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { SalientPoints } from '@thd-olt-component-react/salient-points';
import { Favorite } from '@thd-olt-component-react/save-to-favorites';
import { Quantity } from '@thd-olt-component-react/quantity';
import THDCustomer from '~/@thd-olt-global/thd-customer';

import { ProductDetailsButton } from './ProductDetailsButton';
import { FulfillmentLazy } from './FulfillmentLazy';
import { QuantityAdjustmentsContext } from '../QuantityAdjustmentsContext';
import { trigger } from '../analytics';
import './selected-item-details.scss';

const SelectedItemDetails = (props) => {
  const {
    itemId,
    storeId,
    channel,
    displayMobile,
    data,
    packageCategory,
    mountFulfillment,
    selectedProductIndex,
  } = props;

  const { itemMap, setItemMap } = useContext(QuantityAdjustmentsContext);

  const {
    reviews = {},
    identifiers = {},
    keyProductFeatures = {}
  } = data || {};

  const MIN_KPFS_NEEDED_TO_DISPLAY = 3;
  const { averageRating, totalReviews } = reviews.ratingsReviews || {};
  const { modelNumber, canonicalUrl, brandName, productLabel } = identifiers;
  const isGM = packageCategory === 'gm';
  const isItemAvailable = itemMap[itemId]?.availableQty > 0;
  const hasKeyProductFeatures = keyProductFeatures?.keyProductFeaturesItems?.[0]
    ?.features?.length > MIN_KPFS_NEEDED_TO_DISPLAY;
  const isExchangeCustomer = THDCustomer?.isExchangeCustomer;

  const handleQuantityChange = ({ quantity }) => {
    const map = { ...itemMap, [itemId]: { ...itemMap[itemId], selectedQty: quantity } };
    setItemMap(map);
  };

  const dataWithQuantityLimit = () => {
    if (!itemMap?.[itemId]?.quantityLimit) return data;
    return { ...data, info: { quantityLimit: itemMap[itemId].quantityLimit } };
  };

  const onClick = () => {
    trigger({
      itemId,
      position: selectedProductIndex + 1,
      packageCategory,
    });
  };

  return (
    <div className="bundling-product-tabs__info" data-testid="selected-item-details">
      <div className="bundling-product-tabs__reviews-and-favorites">
        <a onClick={onClick} href={canonicalUrl} className="bundling-product-tabs__reviews">
          <div className="bundling-product-tabs__reviews-container">
            <Rating value={parseFloat(averageRating)} type="stars" orange />
            <span className="bundling-product-tabs__reviews-container-total">({totalReviews})</span>
          </div>
          <span className="bundling-product-tabs__review-divider">|</span>
          <div className="bundling-product-tabs__reviews-container-model">Model # {modelNumber}</div>
        </a>
        {!isExchangeCustomer && (
          <div className="bundling-product-tabs__favorites">
            <Favorite showCircle itemId={itemId} storeId={storeId} />
          </div>
        )}
      </div>
      <div className="bundling-product-tabs__branding">
        <a href={canonicalUrl} onClick={onClick}>
          <span className="bundling-product-tabs__branding-brand">{brandName}</span>
          <span className="bundling-product-tabs__branding-product">{productLabel}</span>
        </a>
      </div>
      <div className="bundling-product-tabs__pricing">
        <Price
          itemId={itemId}
          channel={channel}
          storeId={storeId}
          displayEachUom={false}
          large={false}
          product={dataWithQuantityLimit()}
        />
      </div>
      {hasKeyProductFeatures && (
        <div className="bundling-product-tabs__specs">
          <p className="bundling-product-tabs__specs-title">Top Specs</p>
          <KeyProductFeatures
            itemId={itemId}
            hideImage
            maxfeatures={6}
            twoColumnGrid={!displayMobile}
            displaySimilarLinks={false}
            simple
          />
        </div>
      )}
      {!hasKeyProductFeatures && isGM && (
        <div className="bundling-product-tabs__salient-points" data-testid="salient-points">
          <SalientPoints itemId={itemId} max={3} />
        </div>
      )}
      <div className="fulfillment-layout">
        {typeof window !== 'undefined' && isGM && mountFulfillment && (
          <Suspense fallback={<div />}>
            <QueryProvider
              dataSource="catalog"
              cacheKey="selected-item-details-fulfillment"
            >
              <FulfillmentLazy itemId={itemId} />
            </QueryProvider>
          </Suspense>
        )}

        <div className="fulfillment-layout__row">
          <ProductDetailsButton itemId={itemId} />
          {(isGM && isItemAvailable) && (
            <div className="bundling-product-tabs__quantity" role="button">
              <Quantity value={itemMap[itemId]?.selectedQty || 1} onChange={handleQuantityChange} noRightMargin />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SelectedItemDetails.dataModel = extend({}, Favorite, Price, KeyProductFeatures, SalientPoints);

SelectedItemDetails.displayName = 'SelectedItemDetails';

SelectedItemDetails.propTypes = {
  itemId: string,
  storeId: string,
  channel: string,
  displayMobile: bool,
  data: shape({}),
  packageCategory: string,
  mountFulfillment: bool,
  selectedProductIndex: number
};

SelectedItemDetails.defaultProps = {
  itemId: '',
  storeId: '',
  channel: '',
  displayMobile: false,
  data: {},
  packageCategory: '',
  mountFulfillment: false,
  selectedProductIndex: 0,
};

export { SelectedItemDetails };
