import React from 'react';
import PropTypes, {
  bool,
  number,
  func,
  string,
  shape
} from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Quantity } from '@thd-olt-component-react/quantity';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { Fulfillment } from '@thd-olt-component-react/fulfillment';
import '../buybox.scss';
import classNames from 'classnames';

const BuyboxUnavailable = ({
  itemId,
  quantity,
  onFulfillmentChange,
  onDeliveryOptionsZipCodeChange,
  hideQuantity,
  onQuantityChange,
  quantityLimitExceed,
  directData,
  configurableProductDetails,
  isTwoTileEnabledFS,
  originalConfigId
}) => {
  const showCards = true;
  const wrapperClasses = classNames('buybox-wrapper', { 'buybox-wrapper--card-background': showCards });
  const cardClasses = classNames({ 'buybox-top__wrapper': !showCards, buybox__card: showCards });

  return (
    <Row>
      <Col fallback="12" nopadding={showCards} className={wrapperClasses} data-component="Buybox">
        <div className={cardClasses}>
          <div className="buybox-top__wrapper">
            <div className="buybox__fulfillment-wrapper">
              <Fulfillment
                itemId={itemId}
                quantity={quantity}
                originalConfigId={originalConfigId}
                onChange={onFulfillmentChange}
                onDeliveryZipCodeChange={onDeliveryOptionsZipCodeChange}
                directData={directData}
                configurableProductDetails={configurableProductDetails}
                isTwoTileEnabledFS={isTwoTileEnabledFS}
              />
            </div>
          </div>
          <Row>
            {!hideQuantity && (
              <div className="buybox__quantity--wrapper pt-20">
                <Quantity
                  value={quantity}
                  onChange={onQuantityChange}
                  className={quantityLimitExceed ? 'buybox__quantity--warning' : ''}
                />
              </div>
            )}
            <div className="buybox__actions--atc sui-pt-5">
              <AddToCart disabled> Add to Cart </AddToCart>
            </div>

          </Row>
          <div className="alert-inline alert-inline--danger">
            <span className="alert-inline__message">Unavailable combination. Please make another selection.</span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

BuyboxUnavailable.propTypes = {
  itemId: string.isRequired,
  quantity: number,
  onFulfillmentChange: func,
  onDeliveryOptionsZipCodeChange: func,
  hideQuantity: bool,
  onQuantityChange: func,
  quantityLimitExceed: bool,
  originalConfigId: string,
  /* eslint-disable-next-line react/forbid-prop-types */
  directData: PropTypes.objectOf(PropTypes.any),
  isTwoTileEnabledFS: bool,
  configurableProductDetails: PropTypes.shape({
    isSpecialOrder: bool,
    configuratorHideQuantity: bool,
    currentSelectedFulfillment: string,
    /* eslint-disable-next-line react/forbid-prop-types */
    configuratorData: PropTypes.objectOf(PropTypes.any),
  })
};

BuyboxUnavailable.defaultProps = {
  quantity: 1,
  onFulfillmentChange: () => {},
  onDeliveryOptionsZipCodeChange: () => {},
  hideQuantity: false,
  onQuantityChange: () => {},
  quantityLimitExceed: false,
  originalConfigId: null,
  directData: null,
  isTwoTileEnabledFS: false,
  configurableProductDetails: {
    isSpecialOrder: false,
    configuratorHideQuantity: false,
    currentSelectedFulfillment: '',
    configuratorData: {},
  }
};

export { BuyboxUnavailable };
