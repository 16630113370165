import { useEffect } from 'react';
import { useStoreId } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import { determineItemOrItemTypes } from './utils';

export const useIsMajorAppliance = ({
  itemId,
  itemIds,
  storeId,
  responseOnly,
  isMajorAppliance,
  isPickupDrawer
}) => {
  const expStoreId = useStoreId();
  const storeIdToUse = storeId || expStoreId;

  if (typeof isMajorAppliance === 'boolean') {
    return {
      isMajorApplianceItemOrItems: isMajorAppliance,
      loading: null,
      error: null
    };
  }

  if (responseOnly || isMajorAppliance || isPickupDrawer) {
    return {
      isMajorApplianceItemOrItems: isMajorAppliance || null,
      loading: null,
      error: null
    };
  }

  // TODO: Don't disable this. It can cause the page to break.
  // Create a skip based off of the early returns from above. Then return after the hooks with the conditionals.
  // const skip = typeof isMajorAppliance === 'boolean' || responseOnly || ...

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [getProducts, productsResponse] = useDataModel('clientOnlyProducts', {
    variables: {
      itemIds: (itemId ? [itemId] : itemIds)
    },
    ssr: false,
    skip: storeIdToUse === '8119'
  }, true);

  // TODO: Don't disable this. It can cause the page to break.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getProducts();
  }, [itemIds, itemId]);
  const { data, loading, error } = productsResponse;

  return {
    isMajorApplianceItemOrItems: determineItemOrItemTypes(data),
    loading,
    error
  };
};
