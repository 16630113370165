import React, { useEffect, useRef, useState, Suspense } from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import { bool, string, node } from 'prop-types';
import { clickEvent, loadEvent } from '../../utils/publisher';
import { getPaymentEstimatorDetails } from '../../utils/payment-estimator-utils';
import { PLCN_CARD_IMAGE_SMALL } from '../../utils/constants';
import TermsAndConditionsDrawer from '../../common/terms-and-conditions-drawer.component';
import './payment-estimator.style.scss';

export const PaymentEstimator = ({
  isMobile, price, isAppliance, isHDHome, paymentEstimatorLoaded
}) => {
  // price.toString();
  const modalRef = useRef({});
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);

  function updatePaymentEstimatorDetails() {
    setPaymentDetails(getPaymentEstimatorDetails(price, isMobile, isAppliance, isHDHome, 'pip'));
  }

  useEffect(() => {
    if (paymentEstimatorLoaded) {
      loadEvent('plcc 850+ est');
      updatePaymentEstimatorDetails();
    }
  }, [price, paymentEstimatorLoaded]);

  const {
    activeEndDate, activeStartDate, applyNowUrl, displayPrice, promotionTerm, tooltipText,
  } = paymentDetails || {};
  const isAppliancePromo = tooltipText?.includes('Appliance');
  const isTieredPromo = tooltipText?.includes('24 Months');

  const closeDrawer = () => {
    setDisplayDrawer(false);
  };

  return (
    <div className="payment-estimator sui-inline-flex sui-items-center">
      <Image
        src={PLCN_CARD_IMAGE_SMALL}
        alt="Apply Now"
        title="Apply Now"
        height={50}
        width={50}
        className="sui-pr-2"
      />
      <div className="payment-estimator-message">
        <span><b>{`$${displayPrice}.00 `}</b>/mo** suggested payments with ${promotionTerm} months** financing</span>

        <span className="apply-now-link">
          <a
            href={applyNowUrl}
            id="credit-promo-apply-now"
            className="u__default-link"
            target="blank"
            onClick={() => clickEvent('plcc 850+ est')}
          >
            Apply Now
          </a>
          <div className="u__inline credit-info-icon-wrapper" ref={modalRef}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <span
              onClick={setDisplayDrawer}
              onKeyDown={setDisplayDrawer}
              role="button"
              tabIndex={0}
              id="credit-info-icon"
              className="credit-info-icon"
            />
          </div>
        </span>
      </div>
      {applyNowUrl && displayDrawer && (
        <TermsAndConditionsDrawer
          closeDrawer={closeDrawer}
          open={displayDrawer}
          showFinancing
          applyNowClick={() => clickEvent('plcc-est')}
          isAppliance={isAppliance}
          isHDHome={isHDHome}
          price={price}
        />
      )}
    </div>
  );
};

PaymentEstimator.displayName = 'PaymentEstimator';

PaymentEstimator.propTypes = {
  isMobile: bool,
  price: string.isRequired,
  isAppliance: bool,
  isHDHome: bool,
  paymentEstimatorLoaded: bool,
};

PaymentEstimator.defaultProps = {
  isMobile: false,
  isAppliance: false,
  isHDHome: false,
  paymentEstimatorLoaded: false,
};
