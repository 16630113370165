/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useStoreId, ExperienceContext } from '@thd-nucleus/experience-context';

export const usePackages = ({ itemId, callOnLoaded }) => {
  const storeId = useStoreId();
  const { path } = useContext(ExperienceContext);
  let previousUrl = '';
  if (typeof window === 'object' && path?.includes('/p/')) {
    previousUrl = document.referrer;
  }
  const previousUrlMatch = previousUrl.match(/package_([a-zA-Z]|[0-9])*/);

  const { loading, data } = useDataModel('packages', {
    variables: {
      packageId: previousUrlMatch ? previousUrlMatch?.[0] : undefined,
      itemId: previousUrlMatch ? undefined : itemId,
      storeId,
      limit: previousUrlMatch ? 1 : null,
    },
    context: {
      withAuth: true
    },
    ssr: true
  });

  const [fulfillmentFetched, setFulfillmentFetched] = useState(false);
  const [itemMap, setItemMap] = useState({});

  const filteredData = data?.packages?.filter((bundle) => bundle?.products?.length > 1 && bundle?.metadata);
  const hasEnoughPackageData = !!filteredData?.length;

  useEffect(() => {
    if (data) {
      callOnLoaded({ didRender: hasEnoughPackageData });
    }

    if (hasEnoughPackageData) {
      setItemMap(filteredData[0].products.reduce((map, product) => {
        if (product?.itemId) {
          return {
            ...map,
            [product.itemId]: { ...map[product.itemId], selectedQty: 1 }
          };
        }
        return map;
      }, itemMap));
    }
  }, [data]);

  return {
    data,
    loading,
    hasEnoughPackageData,
    itemMap,
    setItemMap,
    fulfillmentFetched,
    setFulfillmentFetched
  };
};