import React, { useState, useEffect, useContext, useMemo } from 'react';
import { string, shape } from 'prop-types';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import {
  useDataModel,
  params,
  extend,
  shape as dsShape,
  string as dsString,
  QueryProvider,
  number as dsNumber,
  bool as dsBool,
  arrayOf as dsArrayOf
} from '@thd-nucleus/data-sources';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { getKevelPIPTestProducts, triggerNewRelic } from '../../utils/helpers';
import './SponsoredPipBanner.scss';
import { initImpressionWatchers } from '../../utils/initImpressionWatchers';
import { FORCE_KEVEL, adServerClients, bannerTypes, schemas } from '../../constants/constants';
import { newRelicConstants } from '../../constants';
import { buildRevJetBanner } from '../../utils/revjet';
import useFbtSkuListener from '../../hooks/useFbtSkuListener';
import { setAppId } from '../../utils/queryParamsHelpers';

const SponsoredPipBannerComponent = (props) => {
  const { mcvisID } = useThdCustomer();
  const [hasData, setHasData] = useState(false);
  const experienceContext = useContext(ExperienceContext);
  let { browserId, pageContext } = props;
  const schema = schemas.PIP_SPONSORED;
  const schemaId = `${schema}__banner_${pageContext.data.treatment}`;
  const maxWidth = experienceContext.channel === 'desktop' ? '100%' : '450px';
  const { storeId } = useStore({
    varnish: true,
    online: true
  });
  experienceContext.useStoreId = storeId;
  const isKevelTestEnabled = useConfigService('fs-prop:sponsored-isKevelTestingEnabled') || false;
  const kevelPIPTestProducts = useConfigService('fs-prop:kevel-pip-test-products') || [];
  const kevelPIPTestProductsArray = getKevelPIPTestProducts(kevelPIPTestProducts);
  const forceKevel = useConfigService(FORCE_KEVEL) || false;

  // KEVEL TESTING: if test is enabled and the product is in the list,
  // use kevel, otherwise use PIQ
  const adServer = (isKevelTestEnabled || forceKevel)
   && (kevelPIPTestProductsArray.includes(pageContext?.data?.itemId) || forceKevel)
    ? adServerClients.KEVEL
    : adServerClients.PIQ;

  const bannerType = bannerTypes.MIDDLE;
  const marketingId = browserId || mcvisID || '';
  const pageType = schema;

  const customerType = useMemo(() => {
    return experienceContext.customer?.type ? experienceContext.customer.type : 'b2c';
  }, [experienceContext.customer?.type]);
  const appId = useMemo(() => setAppId({ experienceContext }), [experienceContext?.channel]);

  const customerIdentifier = typeof window !== 'undefined' && window.cookieUtils?.readBrowserCookie('thda.u');
  const anchorSku = pageContext.data.itemId;
  const { fbtSkus } = useFbtSkuListener(pageContext?.data?.itemId);
  const callBannerApi = useConfigService('isBannerServiceEnabled');
  const requestConfig = {
    adServer,
    mcvisID,
    browserId,
    schema,
    schemaId,
    experienceContext,
    pageContext,
    setHasData,
    bannerType: bannerTypes.MIDDLE,
    eventBusData: { fbtSkus },
    callBannerApi
  };
  const skip = appId === 'mobileapp';
  const { data, loading, error } = useDataModel('sponsored', {
    skip,
    ssr: false,
    variables: {
      bannerType,
      adServer,
      marketingId,
      pageType,
      customerType,
      channel: appId,
      storeId,
      customerIdentifier,
      anchorSku
    }
  });

  useEffect(() => {
    const adType = newRelicConstants.PIP_BANNER;
    if (!loading && !error && data?.sponsored?.banner) {
      setHasData(true);
      const sponsoredData = data.sponsored;
      buildRevJetBanner({ data: sponsoredData, requestConfig, adType });
    }
    if (error) {
      triggerNewRelic(adType, 'BANNER-API-FAILURE');
    } else if (data && data.sponsored) {
      triggerNewRelic(adType, 'BANNER-API-200');
    } else {
      triggerNewRelic(adType, 'BANNER-API-204');
    }
  }, [loading, error, data]);

  useEffect(() => {
    initImpressionWatchers();
  }, [hasData]);

  return (hasData && (
    <div
      data-component="SponsoredPipBanner"
      className="sponsored_pip_banner_container"
      style={{ maxWidth }}
    >
      <div id={schemaId} data-testid="sponsored-pip-banner" className="sponsored_pip_banner" />
    </div>
  ));
};
const propTypes = {
  browserId: string,
  pageContext: shape({
    label: string,
    treatment: string,
    data: shape({
      itemId: Number
    })
  })
};

const defaultProps = {
  browserId: '',
  pageContext: {}
};

SponsoredPipBannerComponent.propTypes = propTypes;
SponsoredPipBannerComponent.defaultProps = defaultProps;
SponsoredPipBannerComponent.dataModel = extend({
  sponsored: params({
    bannerType: dsString(),
    adServer: dsString(),
    marketingId: dsString().isRequired(),
    pageType: dsString().isRequired(),
    customerType: dsString(),
    channel: dsString().isRequired(),
    storeId: dsNumber(),
    customerIdentifier: dsString(),
    anchorSku: dsNumber({ float: true })
  }).shape({
    banner: dsShape({
      bannerURL: dsString(),
      clickthruURL: dsString(),
      onClickBeacon: dsString(),
      clickConversionBeacon: dsString(),
      viewConversionBeacon: dsString(),
      onViewBeacon: dsString(),
      slotId: dsString(),
      campaignId: dsString(),
      placementId: dsString(),
      flightId: dsString()
    }),
    customerRecentlyViewedSkus: dsShape({
      skus: dsString(),
      refreshAt: dsString()
    })
  })
});

const QueryProvidedSponsoredPipBanner = (props) => {

  const skipFn = ({ skip, queryName }) => {
    return skip;
  };

  return (
    <QueryProvider cacheKey="sponsored-pip-banner" skip={skipFn}>
      <SponsoredPipBannerComponent {...props} />
    </QueryProvider>
  );
};
QueryProvidedSponsoredPipBanner.propTypes = propTypes;

QueryProvidedSponsoredPipBanner.defaultProps = defaultProps;
QueryProvidedSponsoredPipBanner.dataModel = extend({}, SponsoredPipBannerComponent);
const SponsoredPipBanner = withErrorBoundary(
  withDynamicComponent(
    withHydrator(
      {
        id: 'hydrated-sponsored-pip-banner',
        className: 'sponsored-pip-banner-hydrator',
        scrollBuffer: 100
      },
      QueryProvidedSponsoredPipBanner
    )
  )
);
SponsoredPipBanner.displayName = 'SponsoredPipBanner';
export { SponsoredPipBanner };
