/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodSticker,
  PodSection,
  PodSpacer,
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductRating
} from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { AddToList } from '@thd-olt-component-react/add-to-list';

export const FBTCustomProductPod = ({
  itemId, storeId, itemIndex, data, channel, strategy, scheme
}) => {
  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      analyticsData={{ parent: 'fbt-carousel', position: itemIndex, strategy, scheme }}
      render={(pod) => (
        <>
          <PodSticker position="top-left">
            <ProductBadge itemId={pod.itemId} storeId={storeId} />
          </PodSticker>
          <PodSticker position="top-right">
            <AddToList
              itemId={pod.itemId}
              storeId={storeId}
              showIconButton
            />
          </PodSticker>
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
          />
          <PodSection columnAlign>
            <PodSpacer padding={['TOP']}>
              <ProductHeader
                brand="above"
                itemId={pod.itemId}
                brandTitleMaxLine={3}
                disableShopThisCollection
              />
            </PodSpacer>
            <ProductRating itemId={pod.itemId} />
            <Price
              channel={channel}
              itemId={pod.itemId}
              large={false}
              product={data}
              showPreferredPricingBadge
              hideSavingsText={false}
            />
          </PodSection>
        </>
      )}
    />
  );
};

FBTCustomProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductRating,
  ProductHeader,
  Price,
  AddToList
);

FBTCustomProductPod.displayName = 'FBTCustomProductPod';