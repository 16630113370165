import { useEffect, useState, useCallback } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useConfigService } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  shimThdCustomerHook
} from '@thd-olt-component-react/cart-common-data';
import * as helper from '../../BuyboxHelper';
import { FULFILLMENT_METHOD_STH } from '../../constants';

export const useBuyBox = ({ deliveryZipCode }) => {
  const state = {
    disableATC: false,
    paypalEligible: false,
    quantity: 1,
    coverage: 0,
    fulfillmentMethod: FULFILLMENT_METHOD_STH,
    availableFulfillments: [],
    zipCode: deliveryZipCode,
    productAddOns: {
      warrantyItem: {
        itemId: null
      },
      attachedLabor: [{
        attachedLaborSku: null
      }],
      service: {
        type: null,
        selected: false,
        category: null,
      },
      subscriptions: {
        frequency: null,
        ogModule: 'pdp_nocontent'
      },
      dualPath: false,
    },
    isShowATC: false,
    addAdditional: true,
    quantityExceeded: false,
    fallbackMode: false,
    showFencingMessage: false,
    minimumBopisQuantity: null,
    maximumBopisQuantity: null
  };
  const [buyboxState, setBuyboxState] = useState(state);
  const isPaypalB2BEnabled = useConfigService('PAYPAL_ENABLE_B2B');
  const { isB2BCustomer, isB2CCustomer } = shimThdCustomerHook(useThdCustomer());

  // paypal logic
  const cartListener = useCallback((payload) => {
    const { eventName } = payload;
    if (eventName === 'success') {
      // just make paypalEligible false?
      setBuyboxState((prevState) => {
        return {
          ...prevState,
          paypalEligible: false
        };
      });
    }
  }, []);

  // paypal logic
  const checkPaypalEligible = useCallback(() => {
    const paypalEligible = helper.isPaypalEligible(isB2BCustomer, isB2CCustomer, isPaypalB2BEnabled);
    setBuyboxState((prevState) => {
      return {
        ...prevState,
        paypalEligible
      };
    });
  }, []);
  const initializeListeners = useCallback(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('add-to-cart.success', cartListener);
    window.LIFE_CYCLE_EVENT_BUS.on('select-a-store.success', cartListener);
    window.LIFE_CYCLE_EVENT_BUS.on('instant-checkout.success', cartListener);
  }, []);

  const clearListeners = useCallback(() => {
    window.LIFE_CYCLE_EVENT_BUS.off('add-to-cart.success', cartListener);
    window.LIFE_CYCLE_EVENT_BUS.off('select-a-store.success', cartListener);
    window.LIFE_CYCLE_EVENT_BUS.off('instant-checkout.success', cartListener);
  }, []);

  // paypal logic
  useEffect(() => {
    checkPaypalEligible();
    initializeListeners();
    return function cleanup() {
      clearListeners();
    };
  }, []);

  useEffect(() => {
    if (deliveryZipCode !== buyboxState.storeZip) {
      setBuyboxState((existingState) => ({
        ...existingState,
        storeZip: deliveryZipCode
      }));
    }
  }, [deliveryZipCode]);

  return [buyboxState, setBuyboxState];
};

export const useBuyboxGetData = ({
  itemId,
  storeId,
  directData = null,
  quantity = 1,
  zipCode
}) => {
  let queryVariables = {
    itemId,
    storeId,
    zipCode,
  };
  if (quantity > 1) {
    queryVariables = {
      ...queryVariables,
      quantity
    };
  }

  const productOptions = {
    skip: storeId === '8119' || !!directData,
    ssr: false,
    variables: queryVariables
  };

  const { data, loading, error } = useDataModel('clientOnlyProduct', productOptions);

  return { data: data || { product: directData }, loading, error };
};
