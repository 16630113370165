import React from 'react';
import PropTypes from 'prop-types';
import { ShippingThreshold } from './ShippingThreshold';
import {
  formatDate,
  formatWeek
} from '../../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { FULFILLMENT_METHODS } from '../../../constants';
import { IDS } from '../../../../automationTestIds';

export const ApplianceShipping = ({
  deliveryStartDate,
  hasFreeShipping,
  hideShippingThreshold
}) => {
  const startDate = formatDate(deliveryStartDate);
  const formattedEarliestDelivery = formatWeek(deliveryStartDate, true, startDate);
  return (
    <div className="fulfillment-tile">
      <TileHead
        title="Delivery"
        automationId={IDS.LABEL.sthLabel}
      />
      <div className="fulfillment__sub-title">
        {deliveryStartDate && (
          <>
            <div className="delivery-timeline" data-automation-id={IDS.ETA.sthETA}>Earliest delivery date</div>
            <div className="u__bold fulfillment-qty-row" data-automation-id={IDS.QTY.sthQty}>
              {formattedEarliestDelivery}
            </div>
          </>
        )}
      </div>
      <TileFooter>
        {!hideShippingThreshold
        && (
          <ShippingThreshold
            type={FULFILLMENT_METHODS.APPLIANCE}
            hasFreeShipping={hasFreeShipping}
          />
        )}
      </TileFooter>
    </div>
  );
};

ApplianceShipping.propTypes = {
  deliveryStartDate: PropTypes.string,
  hasFreeShipping: PropTypes.bool
};

ApplianceShipping.defaultProps = {
  deliveryStartDate: null,
  hasFreeShipping: false
};
