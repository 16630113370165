import React, { useEffect } from 'react';
import {
  string,
  bool as boolType,
  number as numberType,
  array,
  object,
  oneOfType,
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { RecsFBTProductPodCarousel } from './RecsFBTProductPodCarousel';

export const RecsProductPod = (props) => {
  const {
    hideTitle,
    hideATC,
    slidesPer,
    normalizedProducts,
    itemId
  } = props;
  const { storeId, storeZip: zipCode, membershipInformation } = useStore();
  useEffect(() => {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined' && normalizedProducts) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('RecsFBT.ready', { products: normalizedProducts });
    }
  }, []);

  return (
    <>
      <RecsFBTProductPodCarousel
        storeId={storeId}
        zipCode={zipCode}
        itemId={itemId}
        hideATC={hideATC}
        membershipInformation={membershipInformation}
        hideTitle={hideTitle}
        loadingImageHeight={250}
        loadingLinesCount={10}
        data={normalizedProducts}
        slidesPer={slidesPer}
      />
    </>
  );
};

RecsProductPod.propTypes = {
  hideATC: boolType,
  hideTitle: boolType,
  slidesPer: numberType,
  normalizedProducts: oneOfType([array, object]),
  itemId: string
};

RecsProductPod.defaultProps = {
  hideATC: true,
  hideTitle: false,
  slidesPer: null,
  normalizedProducts: null,
  itemId: null
};
