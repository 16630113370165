import React, { createContext, useContext } from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOfType,
  shape,
  string
} from 'prop-types';
import { Link } from '@one-thd/sui-atomic-components';
import { DEFAULT_ROLL_WIDTH } from '../../constants';
import { useCalculator } from '../../hooks/useCalculator';
import { ToasterContext } from './FlooringCalculator/Toaster/ToasterProvider';
import { getCalculatorLocalStorage } from '../../helpers/localStorageUtil';

const toasterContent = ({ areaName, actionPerformed }) => (
  <div className="sui-grid sui-grid-flow-col sui-gap-1">
    <div className="sui-truncate">{areaName}</div>
    <div>{actionPerformed}</div>
  </div>
);

export const CalculatorContext = createContext({});

export const CalculatorProvider = ({
  allowAdditionalCoverage,
  calculatorType,
  children,
  lineItemName,
  info,
  onCalculate,
  taxonomy,
  unitsPerCase
}) => {

  const { label: l1Label } = taxonomy?.breadCrumbs?.[0] || { label: '' };
  const [
    calculate,
    {
      addElement, calculateTotal, removeInputElement, saveToLocalStorageFunc, setCalculateStateAndRef, undoRemoveElement
    }
  ] = useCalculator({
    allowAdditionalCoverage, calculatorType, info, l1Label, lineItemName, onCalculate, unitsPerCase
  });
  const { areas } = calculate;

  // Calculator-based toaster methods
  const { addToaster, removeToaster } = useContext(ToasterContext);
  const addSavedAreaToaster = ({ selectedArea }) => {
    const { key, name } = areas[selectedArea];
    const saveKey = `save-${key}`;
    addToaster(
      saveKey,
      {
        children: toasterContent({ areaName: name, actionPerformed: 'has been saved' }),
        timeoutKey: saveKey,
        showCloseButton: true,
        status: 'success'
      }
    );
  };

  const addRemovedAreaToaster = ({ selectedArea }) => {
    const { key, name } = selectedArea !== null ? areas[selectedArea] : { key: 'all' };
    const deleteKey = `delete-${key}`;
    addToaster(deleteKey, {
      action: (
        <div className="sui-flex sui-mr-4">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            color="info"
            component="button"
            onClick={() => {
              undoRemoveElement(deleteKey);
              removeToaster({ key: deleteKey });
            }}
            underline="always"
            weight="bold"
          >
            UNDO
          </Link>
        </div>
      ),
      children: name
        ? toasterContent({ areaName: name, actionPerformed: 'has been deleted' })
        : <>All areas have been deleted</>,
      timeoutKey: deleteKey,
      status: 'success'
    });
  };

  const addUnsavedChangesToaster = ({ selectedArea }) => {
    const { storageObj } = getCalculatorLocalStorage({
      l1Label
    });
    const { areas: storageAreas } = storageObj || { areas: [] };
    const existingAreaChanged = typeof storageAreas[selectedArea] !== 'undefined';

    const { key, name } = areas[selectedArea];
    const unchangedKey = `save-${key}`;
    const actionMsg = existingAreaChanged ? 'changes not saved' : 'was not saved';

    addToaster(
      unchangedKey,
      {
        children: toasterContent({ areaName: name, actionPerformed: actionMsg }),
        timeoutKey: unchangedKey,
        showCloseButton: true,
        status: 'warning'
      }
    );
  };

  return (
    <CalculatorContext.Provider value={{
      addElement,
      addRemovedAreaToaster,
      addSavedAreaToaster,
      addUnsavedChangesToaster,
      calculate,
      calculateTotal,
      calculatorType,
      l1Label,
      lineItemName,
      removeInputElement,
      saveToLocalStorageFunc,
      setCalculateStateAndRef
    }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};

CalculatorProvider.propTypes = {
  allowAdditionalCoverage: bool,
  calculatorType: string,
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  info: shape({
    pipCalculator: shape({
      coverageUnits: string, // Dimensional units Ex: "Panel", "Block", "Sheet", "Bag"
      display: bool, // Whether or not to display the calculator
      publisher: number, // units per case
      toggle: string, // "2-Point" (sq. ft.), "3-Point" (cu. ft.), or "Fixed_Width" (linear ft.)
      defaultAdditionalCoverage: number, // Whether additional coverage is used by default
      additionalCoveragePercentage: bool // The additional coverage percentage
    })
  }),
  lineItemName: string,
  onCalculate: func,
  taxonomy: shape({
    breadCrumbs: arrayOf(shape({
      label: string
    }))
  }).isRequired,
  unitsPerCase: oneOfType([string, number])
};

CalculatorProvider.defaultProps = {
  allowAdditionalCoverage: false,
  calculatorType: '',
  info: {},
  lineItemName: 'Area',
  onCalculate: null,
  unitsPerCase: '0'
};
