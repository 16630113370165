import React, { useContext, useEffect, useState } from 'react';
import { useDomPath } from '@thd-olt-functional/utils';
import { Col } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { CheckAvailabilityContext } from '../CheckAvailabilityContext';
import { processResponse, setDeliveryZipCookie } from '../helpers/utils';
import { InputForm } from './subcomponents/inputForm/InputForm';
import { AvailabilityMessage } from './subcomponents/depotDirectResponse/AvailabilityMessage';
import { useCheckAvailability } from '../helpers/useCheckAvailability';
import '../styles/depot-direct-service.scss';

export const DepotDirectService = () => {
  const {
    zip,
    setZip,
    itemId,
    itemIds,
    onError,
    autoCheck,
    showLoader,
    responseOnly,
    onSubmitForm,
    defaultMessaging,
    hideInput: hideInputComponentAltogether,
    checkAvailabilityModel
  } = useContext(CheckAvailabilityContext);

  const [domPath, ref] = useDomPath();
  const [showInput, setShowInput] = useState(!checkAvailabilityModel);
  const [processedResponseData, setProcessedResponseData] = useState();
  const [{ data, loading, error }, execute] = useCheckAvailability(itemId, itemIds);
  const { isConsumerApp } = useContext(ExperienceContext);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const checkAvailability = (payload, buttonClicked = false) => {
    setIsButtonClicked(buttonClicked);
    execute(payload);
  };

  const onSubmit = (event, zipCode) => {
    if (event) event.preventDefault();
    checkAvailability(`${itemId || itemIds}/zipCode/${zipCode}`, true);
  };

  const refetchForConsumer = ({ itemId: singleId, itemIds: multipleIds, zip: zipCode }) => {
    checkAvailability(`${singleId || multipleIds}/zipCode/${zipCode}`);
  };

  useEffect(() => {
    if (autoCheck && zip && !checkAvailabilityModel) checkAvailability(`${itemId || itemIds}/zipCode/${zip}`);
    if (checkAvailabilityModel) {
      setProcessedResponseData(checkAvailabilityModel);
    }
  }, [checkAvailabilityModel]);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.on('check-availability.depot-direct-success', (eventData) => {
      const { zipCode, stringifiedQueryId, processedData } = eventData?.output;
      if (hideInputComponentAltogether) {
        if (stringifiedQueryId === (itemId || itemIds?.join())) {
          setProcessedResponseData(processedData);
          return;
        }

        if (zipCode !== zip) {
          checkAvailability(`${itemId || itemIds}/zipCode/${zipCode}`, { useCache: true });
        }
      }
    });
    if (isConsumerApp && data && !loading) {
      if (onSubmitForm) {
        const processedData = processResponse(data);
        onSubmitForm({
          zipCode: zip,
          processedData,
          rawResponse: data,
          refetch: refetchForConsumer,
          autoCheck
        });
      }
    }
  }, [zip]);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
    if (!loading && data) {
      const processedData = processResponse(data);
      setProcessedResponseData(processedData);
      setZip(processedData.zipCode);
      if (onSubmitForm) {
        onSubmitForm({
          zipCode: processedData.zipCode || zip,
          processedData,
          rawResponse: data,
          refetch: refetchForConsumer,
          autoCheck
        });
      }

      if (!hideInputComponentAltogether) {
        if (processedData?.earliestAvailabilityDate) {
          setDeliveryZipCookie(processedData.zipCode);
        }
        setShowInput(false);
        const eventData = {
          zipCode: processedData.zipCode || zip,
          processedData,
          stringifiedQueryId: itemIds ? itemIds.join() : itemId,
          refetch: refetchForConsumer,
          autoCheck: isButtonClicked ? false : autoCheck,
          inputFormVisible: !hideInputComponentAltogether,
          domPath
        };

        LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('check-availability.depot-direct-success', eventData);
        LIFE_CYCLE_EVENT_BUS.trigger('check-availability.depot-direct-success', eventData);
      }
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (checkAvailabilityModel) {
      checkAvailability(`${itemId || itemIds}/zipCode/${zip}`);
    }
  }, [itemId, itemIds?.toString()]);

  if (showLoader && loading && !responseOnly) {
    return (
      <Col className="depot-direct-service__loading-spinner">
        <span className="segment-spinner segment-spinner--small" />
      </Col>
    );
  }

  if (hideInputComponentAltogether && defaultMessaging && processedResponseData) {
    return (
      <Col nopadding>
        <AvailabilityMessage availabilityData={processedResponseData} />
      </Col>
    );
  }

  return (
    <div className="depot-direct-service" ref={ref}>
      {!hideInputComponentAltogether
      && showInput
      && (
        <InputForm onSubmit={onSubmit} />
      )}
      { !showInput
      && (
        <Col nopadding>
          { defaultMessaging
          && processedResponseData
          && (
            <Col nopadding>
              <AvailabilityMessage availabilityData={processedResponseData} />
            </Col>
          )}
          {!isConsumerApp
          && (
            <Col nopadding>
              <button
                type="button"
                className="u__default-link"
                onClick={() => setShowInput(true)}
              >
                Change Delivery Zip Code
              </button>
            </Col>
          )}
        </Col>

      )}
    </div>
  );
};
