import React, { useContext } from 'react';
import { shape, string, bool, arrayOf } from 'prop-types';
import { Col } from '@thd-olt-component-react/core-ui';
import { CheckAvailabilityContext } from '../../../CheckAvailabilityContext';
import {
  AVAILABLE,
  BACK_ORDERED,
  OOS_ETA_UNAVAILABLE,
  OOS_ALT_MODEL,
  UNAVAILABLE,
  ERROR
} from '../../../helpers/constants';
import './availability-message.scss';

export const available = (earliestAvailabilityDate, zipCode, changeAvailableDateMessage) => {
  return (
    <>
      <span>{ changeAvailableDateMessage ? 'Estimated delivery date' : 'Earliest delivery date' }&nbsp;</span>
      <span>
        <span className="u__bold">{earliestAvailabilityDate}</span> to <span className="u__bold">{zipCode}</span>
      </span>
    </>
  );
};
export const backordered = (earliestAvailabilityDate, zipCode, descriptorType) => {
  return (
    <>
      <div className="alert-inline alert-inline--warning availability-message--backordered">
        <div>
          <span>{`This ${descriptorType || 'product'} is currently `}</span>
          <span className="u__bold">backordered. </span>
        </div>
      </div>
      {earliestAvailabilityDate && (
        <div>
          <span>Expect it&nbsp;</span>
          <span className="u__bold">{earliestAvailabilityDate}</span> to <span className="u__bold">{zipCode}</span>
        </div>
      )}
    </>
  );
};
export const outOfStock = (descriptorType) => {
  return (
    <>
      <span className="u__text--danger">{`This ${descriptorType || 'product'} is currently out of stock.`}</span>
    </>
  );
};
export const unavailable = () => {
  return (
    <div className="u__text--danger">
      <span className="u__bold">We&apos;re sorry.&nbsp;</span>
      <span>
        Appliance delivery isn&apos;t offered to your area. Your nearest store may be able to offer other arrangements.
      </span>
    </div>
  );
};
export const errorMessage = () => {
  return (
    <span className="u__text--danger">Sorry, an error occurred.</span>
  );
};

const messageTemplates = (status, dataModel, descriptorType) => {
  const { earliestAvailabilityDate, zipCode, changeAvailableDateMessage } = dataModel;

  switch (status) {
  case AVAILABLE:
    return available(earliestAvailabilityDate, zipCode, changeAvailableDateMessage);
  case BACK_ORDERED:
    return backordered(earliestAvailabilityDate, zipCode, descriptorType);
  case OOS_ETA_UNAVAILABLE:
    return outOfStock(descriptorType);
  case OOS_ALT_MODEL:
    return outOfStock(descriptorType);
  case UNAVAILABLE:
    return unavailable();
  case ERROR:
    return errorMessage();
  default:
    return errorMessage();
  }
};

export const AvailabilityMessage = ({
  availabilityData,
  statusToUse
}) => {

  const {
    isBundle,
    isPackage
  } = useContext(CheckAvailabilityContext);

  if (!availabilityData) return null;
  const availabilityStatus = statusToUse || availabilityData?.defaultMessagingStatusToUse;

  const descriptorType = (isBundle ? 'bundle' : '') || (isPackage ? 'package' : '');

  return (
    <Col className="availability-message" nopadding>
      {messageTemplates(availabilityStatus, availabilityData, descriptorType)}
    </Col>
  );
};

AvailabilityMessage.propTypes = {
  availabilityData: shape({
    zipCode: string,
    hasBackordered: bool,
    hasOutOfStock: bool,
    isUnavailable: bool,
    defaultMessagingStatusToUse: string,
    earliestAvailabilityDate: string,
    products: arrayOf(shape({
      itemId: string,
      rawBackOrderedEstimatedDeliveryDate: string,
      backOrderedEstimatedDeliveryDate: string,
      deliveryDate: string,
      rawDeliveryDate: string,
      primaryStoreNumber: string,
      endDeliveryDate: string,
      status: string
    }))
  }),
  statusToUse: string
};

AvailabilityMessage.defaultProps = {
  availabilityData: null,
  statusToUse: null
};
