import React from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import {
  string, shape, oneOfType, node, arrayOf
} from 'prop-types';

const SelectedItemDetailsWrapper = ({
  itemId, storeId, dataSource, data, children
}) => {
  const mountedFn = () => storeId !== '8119';

  if (storeId === '8119' && !data.itemId) return null;

  return (
    <QueryProvider
      mounted={mountedFn}
      dataSource={dataSource}
      defaultVariables={{ itemId, storeId }}
      cacheKey="selected-item-details"
    >
      {children}
    </QueryProvider>
  );
};

SelectedItemDetailsWrapper.displayName = 'SelectedItemDetailsWrapper';

SelectedItemDetailsWrapper.propTypes = {
  itemId: string,
  storeId: string,
  dataSource: string,
  data: shape({}),
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired
};

SelectedItemDetailsWrapper.defaultProps = {
  itemId: '',
  storeId: '',
  dataSource: 'catalog',
  data: {}
};

export { SelectedItemDetailsWrapper };