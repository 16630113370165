import React from 'react';
import { Skeleton, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';

const ProductConfiguratorSkeleton = () => {

  return (
    <Skeleton disablePadding disableGutters grow>
      <div className='sui-pb-5'>
        <SkeletonLine height={5} variant="single" />
      </div>
      <div className='sui-pb-5'>
        <div className='sui-pb-2'>
          <SkeletonLine height={5} variant="single" />
        </div>
        <div className='sui-pb-2'>
            <Skeleton disablePadding orientation="horizontal" density="normal">
              <SkeletonBlock width={10} height={10} />
              <SkeletonBlock width={10} height={10} />
            </Skeleton>
        </div>
      </div>
    </Skeleton>
)
}

export {ProductConfiguratorSkeleton};
