import React from 'react';
import { number, bool } from 'prop-types';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import classNames from 'classnames';
import './buyboxLoader.scss';

const BuyboxLoader = ({ tilesCount = 3, showCards, lite, noGrid = false }) => {
  const cardFoot = { height: '1rem' };
  const cardHead = { height: '2rem' };

  const cardloaderhead = { height: '20px', marginTop: '15px' };
  const cardloaderbody = { height: '12px', width: '90px', marginTop: '9px' };
  const cardloaderFooter = { height: '15px', marginTop: '18px' };
  const quantityText = { height: '1.3rem', marginTop: '0.6em' };
  const tileArray = [];

  const wrapperClasses = classNames({
    buybox__loader: showCards,
    // buybox-__loader adds negative margin
    // because it expects it to be wrapped in a grid
    'buybox__loader--no-grid': noGrid && showCards,
  });

  for (let i = 0; i < tilesCount; i += 1) {
    tileArray.push(
      <div className="card card-container card-unselected card-disabled" key={i}>
        <div className="loader-container">
          <div className="card-head">
            <LoadingPlaceholder
              type="textRow"
              color="#E0E0E0"
              style={cardloaderhead}
              showLoadingAnimation
              ready={false}
            >
              Buybox Loader
            </LoadingPlaceholder>
          </div>
          <LoadingPlaceholder
            type="textRow"
            color="#E0E0E0"
            style={cardloaderbody}
            showLoadingAnimation
            ready={false}
          >
            Buybox Loader
          </LoadingPlaceholder>
          <LoadingPlaceholder
            type="textRow"
            color="#E0E0E0"
            style={cardloaderbody}
            showLoadingAnimation
            ready={false}
          >
            Buybox Loader
          </LoadingPlaceholder>
          <div className="card-footer">
            <LoadingPlaceholder
              type="textRow"
              color="#E0E0E0"
              style={cardloaderFooter}
              showLoadingAnimation
              ready={false}
            >
              Buybox Loader
            </LoadingPlaceholder>
          </div>
        </div>
      </div>
    );
  }
  if (lite) {
    return (
      <div
        className="buybox__actions--atc"
        style={{ flexBasis: '100%' }}
        data-component="BuyboxLoaderLite"
      >
        <div className="buybox__atc">
          <LoadingPlaceholder
            type="rect"
            color="#E0E0E0"
            style={{ height: '37px' }}
            showLoadingAnimation
            ready={false}
          >
            ATC Loader
          </LoadingPlaceholder>
        </div>
        <div className="fulfillment-loader">
          <LoadingPlaceholder
            type="rect"
            color="#E0E0E0"
            style={{ height: '17px', marginTop: '10px' }}
            showLoadingAnimation
            ready={false}
          >
            Fulfillment Loader
          </LoadingPlaceholder>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={wrapperClasses}
        data-testid="buybox-placeholder-test-id"
        style={{ paddingLeft: '10px', paddingTop: '10px' }}
        data-component="BuyboxLoaderTitle"
      >
        <div className="buybox__loader--title" />
        <div className="buybox__loader-wrapper card-deck">
          {tileArray}
        </div>
        <div className="fulfillment__fullcontent" style={{ minHeight: '51px', maxWidth: '480px' }} />
        <div className="buybox__actions" style={{ maxWidth: '450px' }} />
        <div className="instant-checkout" />
        <div className="paypal-mobile__wrapper " />
      </div>
      <div
        className={wrapperClasses}
        style={{ paddingLeft: '10px', paddingTop: '10px', height: '141px' }}
        data-component="BuyboxLoader"
      >
        <div className="buybox__card">
          <div className="buybox__actions" style={{ width: '100%' }}>
            <div className="buybox__actions--flex" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="buybox__quantity--wrapper" style={{ minWidth: '142px', alignSelf: 'baseline' }}>
                <div className="buybox__quantity ">
                  <div className="quantity-field-wrapper">
                    <div className="quantity-btn">
                      <LoadingPlaceholder
                        type="textRow"
                        color="#E0E0E0"
                        style={quantityText}
                        showLoadingAnimation
                        ready={false}
                      >
                        Quantity Loader
                      </LoadingPlaceholder>
                    </div>
                    <div className="quantity-btn">
                      <LoadingPlaceholder
                        type="textRow"
                        color="#E0E0E0"
                        style={quantityText}
                        showLoadingAnimation
                        ready={false}
                      >
                        Quantity Loader
                      </LoadingPlaceholder>
                    </div>
                    <div className="quantity-btn">
                      <LoadingPlaceholder
                        type="textRow"
                        color="#E0E0E0"
                        style={quantityText}
                        showLoadingAnimation
                        ready={false}
                      >
                        Quantity Loader
                      </LoadingPlaceholder>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buybox__actions--atc" style={{ flexBasis: '100%' }}>
                <div>
                  <div className="buybox__atc">
                    <div className="">
                      <LoadingPlaceholder
                        type="rect"
                        color="#E0E0E0"
                        style={{ height: '37px' }}
                        showLoadingAnimation
                        ready={false}
                      >
                        ATC Loader
                      </LoadingPlaceholder>
                    </div>
                  </div>
                </div>
                <div />
                <div className="paypal-mobile__wrapper " style={{ margin: '10px 0', textAlign: 'center' }}>
                  <div>
                    <LoadingPlaceholder
                      type="textRow"
                      color="#E0E0E0"
                      style={cardFoot}
                      showLoadingAnimation
                      ready={false}
                    >
                      Text 1 Loader
                    </LoadingPlaceholder>
                  </div>
                  <div>
                    <LoadingPlaceholder
                      type="textRow"
                      color="#E0E0E0"
                      style={cardHead}
                      showLoadingAnimation
                      ready={false}
                    >
                      Paypal Loader
                    </LoadingPlaceholder>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BuyboxLoader.propTypes = {
  tilesCount: number,
  showCards: bool,
  lite: bool,
  noGrid: bool,
};

BuyboxLoader.defaultProps = {
  tilesCount: 3,
  showCards: false,
  lite: false,
  noGrid: false,
};

export { BuyboxLoader };