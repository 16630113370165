import React from 'react';
import PropTypes from 'prop-types';
import '../styles/zipInputMobileDrawer.scss';
import { Drawer, DrawerItem, DrawerNav } from '@thd-olt-component-react/drawer';
import { InputForm } from './subcomponents/inputForm/InputForm';
import {
  DELIVERY_ZIP_BODY,
  DELIVERY_ZIP_HEADER,
  DELIVERY_ZIP_INPUT
} from '../helpers/constants';

export const ZipInputMobileDrawer = ({ onSubmit, errorMessage, isQuickViewOpen, setIsQuickViewOpen }) => (
  <div className="ZipInputMobileDrawer__drawerWrapper">
    <Drawer
      open={isQuickViewOpen}
      onClose={() => setIsQuickViewOpen(false)}
      initialItem="mobile-quick-view"
      position="bottom"
      maxSize={35}
      isMobile
    >
      <DrawerItem name="mobile-quick-view">
        <div className="ZipInputMobileDrawer__wrapper">
          <DrawerNav backLinkTxt="">
            <span className="ZipInputMobileDrawer__header">{DELIVERY_ZIP_HEADER}</span>
          </DrawerNav>
          <div className="ZipInputMobileDrawer__divider" />
          <span className="ZipInputMobileDrawer__body">
            {DELIVERY_ZIP_BODY}
          </span>
          <div className="check-availability">
            <InputForm onSubmit={onSubmit} errorMessage={errorMessage} placeholderText={DELIVERY_ZIP_INPUT} />
          </div>
        </div>
      </DrawerItem>
    </Drawer>
  </div>
);

ZipInputMobileDrawer.propTypes = {
  onSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  isQuickViewOpen: PropTypes.bool,
  setIsQuickViewOpen: PropTypes.func,
};

ZipInputMobileDrawer.defaultProps = {
  onSubmit: () => {},
  errorMessage: null,
  isQuickViewOpen: null,
  setIsQuickViewOpen: () => {},
};
