/* eslint-disable max-len */
import React from 'react';
import { InStockNearByStore } from './partials/store/InStockNearByStore';
import { InStockNearByStoreDetails } from './partials/store/InStockNearByStoreDetails';
import { LimitedStockByStore } from './partials/store/LimitedStockByStore';
import { LimitedStockByStoreNearby } from './partials/store/LimitedStockByStoreNearby';
import { DefaultBoSS } from './partials/store/DefaultBoSS';
import { DefaultBoSSDetails } from './partials/store/DefaultBoSSDetails';
import { NotAvailable } from './partials/NotAvailable';
import { BOSSOutOfStock } from './partials/store/BOSSOutOfStock';
import { DefaultBOPIS } from './partials/store/DefaultBOPIS';
import { DefaultBOPISDetails } from './partials/store/DefaultBOPISDetails';
import { StoreOnly } from './partials/store/StoreOnlyTile/StoreOnly';
import { ClearanceStore } from './partials/store/StoreOnlyTile/ClearanceStore';
import { OffshoreBossUnavailable } from './partials/store/OffshoreBossUnavailable';
import { FULFILLMENT_METHODS } from '../constants';
import {
  isStoreOnlyWithClearance,
  getNearByStore,
  getLocalStore,
  getOnlineLocation,
  isBOSSBackordered,
  isBOSSOutOfStock,
  isBopisOutOfStock,
  isBOPISUnavailable,
  isBOSSDominant,
  isCovidAlertEnabled,
  getExcludedStateName,
  isLocationInExcludedStates,
  isNoBOPISForAppliance,
  isMajorApplianceProductType,
  getPickupStoreTimeZone,
  isActiveOrInSeason,
  isItemOnClearance
} from '../helper/utils';
import { BopisNotFulfillableOOS } from './partials/store/StoreOnlyTile/BopisNotFulfillableOOS';

export const getPickupTemplate = ({
  fulfillment,
  type,
  productType,
  channel,
  clearancePricing,
  covidAlert,
  quantity,
  originalConfigId,
  itemId,
  pricing,
  thresholds,
  isCartTiles,
  addOnsAssemblyAndMore,
  fallbackMode,
  isSpecialOrder,
  configuratorHideQuantity,
  disableBOSS,
  disableSTH,
  disablePickupNearBy,
}) => {
  const {
    bopisMinimumThreshold = null,
    bopisMinimumThresholdStores = null,
    bopisHolidayTiming = null
  } = thresholds;
  // TODO: rename type to availabilityType for clarity
  const deliveryService = !disableSTH
    ? (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery')
    : null;
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const bossFulfillment = !disableBOSS
    ? (pickupService?.services || []).find((service) => service.type === 'boss')
    : null;
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');

  const method = bopisFulfillment ? FULFILLMENT_METHODS.BOPIS : FULFILLMENT_METHODS.STORE;
  const nearByStoreLocation = getNearByStore(bopisFulfillment);

  const pickupStoreHours = isCartTiles
    ? (bopisFulfillment?.nearbyStores?.storeHoursModel || bopisFulfillment?.storeHours)
    : { storeTimeZone: getPickupStoreTimeZone(fulfillment) };

  const bossLocation = getOnlineLocation(bossFulfillment);
  const covidEnabled = isCovidAlertEnabled(covidAlert, bopisHolidayTiming);
  const isAppliance = isMajorApplianceProductType(productType);
  const isClearanceInStockOrLQTY = !!(isItemOnClearance(fulfillment) && clearancePricing);

  if ((fallbackMode || bossFulfillment?.fallbackMode) && bossFulfillment) {
    return {
      method: FULFILLMENT_METHODS.BOSS,
      enabled: true,
      quantityLimitExceed: false,
      template: <DefaultBoSS
        fulfillment={bossFulfillment}
        fallbackMode
        isSpecialOrder={isSpecialOrder}
        configuratorHideQuantity={configuratorHideQuantity}
      />,
      subTemplate: <DefaultBoSSDetails
        fulfillment={bossFulfillment}
        isCartTiles={isCartTiles}
      />
    };
  }

  if ((!fulfillment?.fulfillmentOptions || fulfillment?.fulfillmentOptions.length === 0)
    && fulfillment?.bossExcludedShipState) {
    return {
      method,
      enabled: false,
      excluded: true,
      template: <OffshoreBossUnavailable
        itemId={itemId}
        isShipToHomeEligible={!!shippingFulfillment}
        stateName={getExcludedStateName(fulfillment?.bossExcludedShipState)}
      />,
    };
  }
  if ((fulfillment?.bossExcludedShipState !== null)
    && (fulfillment?.bossExcludedShipStates !== undefined)
    && !bossFulfillment
    && !bopisFulfillment) {
    return {
      method,
      excluded: true,
      enabled: false,
      template: <OffshoreBossUnavailable
        itemId={itemId}
        isShipToHomeEligible={!!shippingFulfillment}
        stateName={getExcludedStateName(fulfillment?.bossExcludedShipState)}
        addOnsAssemblyAndMore={addOnsAssemblyAndMore}
      />,
    };
  }
  if (isNoBOPISForAppliance(productType, fulfillment)) {
    return {
      method: FULFILLMENT_METHODS.BOPIS,
      enabled: false,
      unavailable: true,
      template: <NotAvailable
        itemId={itemId}
        type={FULFILLMENT_METHODS.BOPIS}
      />
    };
  }
  if (((!fulfillment?.fulfillmentOptions || !pickupService))
    || (isStoreOnlyWithClearance(fulfillment, type) && !isClearanceInStockOrLQTY)) {
    return {
      method,
      enabled: false,
      outOfStock: true,
      unavailable: true,
      uavl: true,
      template: <NotAvailable
        itemId={itemId}
        type={FULFILLMENT_METHODS.BOPIS}
      />,
    };
  }
  if (!disablePickupNearBy && nearByStoreLocation && !(isClearanceInStockOrLQTY && !isCartTiles)) {
    if (nearByStoreLocation.inventory?.isInStock) {
      return {
        method,
        isPickupNearby: true,
        enabled: true,
        quantityLimitExceed: nearByStoreLocation?.inventory?.quantity > 0
          ? nearByStoreLocation.inventory.quantity < quantity
          : false,
        template: <InStockNearByStore
          covidEnabled={covidEnabled}
          nearByStoreLocation={nearByStoreLocation}
          pickupStoreHours={pickupStoreHours}
          isCartTiles={isCartTiles}
        />,
        subTemplate: <InStockNearByStoreDetails
          fulfillment={bopisFulfillment}
          quantity={quantity}
          pricing={pricing}
          bopisMinimumThreshold={bopisMinimumThreshold}
          bopisMinimumThresholdStores={bopisMinimumThresholdStores}
        />
      };
    }
    if (nearByStoreLocation.inventory?.isLimitedQuantity && !bossFulfillment) {
      return {
        method,
        enabled: false,
        template: <LimitedStockByStoreNearby
          itemId={itemId}
          location={nearByStoreLocation}
        />
      };
    }
    if (!nearByStoreLocation?.inventory?.isInStock && bossFulfillment && !isBOSSOutOfStock(bossFulfillment)) {
      return {
        method: FULFILLMENT_METHODS.BOSS,
        enabled: true,
        quantityLimitExceed: bossLocation?.inventory?.quantity > 0
          ? bossLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultBoSS fulfillment={bossFulfillment} isSpecialOrder={isSpecialOrder} configuratorHideQuantity={configuratorHideQuantity} />,
        subTemplate: <DefaultBoSSDetails
          fulfillment={bossFulfillment}
          isCartTiles={isCartTiles}
        />
      };
    }
  }
  if (bopisFulfillment) {
    const localStoreLocation = getLocalStore(bopisFulfillment);
    if (isClearanceInStockOrLQTY) {
      return {
        method,
        enabled: false,
        template: <ClearanceStore
          quantity={localStoreLocation?.inventory?.quantity}
          pickupStoreHours={pickupStoreHours}
          isLimitedQuantity={localStoreLocation?.inventory?.isLimitedQuantity}
          clearanceStoreName={localStoreLocation?.storeName}
        />
      };
    }
    if (!pickupService.fulfillable) {
      if (localStoreLocation?.inventory?.isInStock && localStoreLocation?.inventory?.quantity > 0) {
        return {
          method,
          enabled: false,
          tooltip: true,
          template: <StoreOnly
            bopisFulfillment={bopisFulfillment}
            isShipToHomeEligible={!!shippingFulfillment}
            itemId={itemId}
            isAppliance={isAppliance}
            isCartTiles={isCartTiles}
            addOnsAssemblyAndMore={addOnsAssemblyAndMore}
            pickupStoreHours={pickupStoreHours}
          />
        };
      }
      if (localStoreLocation?.inventory?.quantity === 0 && isActiveOrInSeason(fulfillment)) {
        return {
          method,
          enabled: false,
          template: <BopisNotFulfillableOOS
            bopisFulfillment={bopisFulfillment}
            isShipToHomeEligible={!!shippingFulfillment}
            itemId={itemId}
            isAppliance={isAppliance}
            location={localStoreLocation}
            isCartTiles={isCartTiles}
            addOnsAssemblyAndMore={addOnsAssemblyAndMore}
            pickupStoreHours={pickupStoreHours}
          />
        };
      }
    }
    if (localStoreLocation?.inventory?.isLimitedQuantity) {
      if (bossFulfillment && !isBOSSOutOfStock(bossFulfillment)) {
        return {
          method: FULFILLMENT_METHODS.BOSS,
          enabled: true,
          quantityLimitExceed: bossLocation?.inventory?.quantity > 0
            ? bossLocation?.inventory?.quantity < quantity
            : false,
          template: <DefaultBoSS fulfillment={bossFulfillment} isSpecialOrder={isSpecialOrder} configuratorHideQuantity={configuratorHideQuantity} />,
          subTemplate: <DefaultBoSSDetails
            fulfillment={bossFulfillment}
            isCartTiles={isCartTiles}
          />
        };
      }
      return {
        method,
        enabled: false,
        template: <LimitedStockByStore
          itemId={itemId}
          location={localStoreLocation}
        />
      };
    }
    if (isBopisOutOfStock(localStoreLocation, nearByStoreLocation) || isBOPISUnavailable(localStoreLocation)) {
      return {
        method,
        enabled: false,
        unavailable: type === 'Store Only',
        outOfStock: true,
        template: <NotAvailable
          itemId={itemId}
          type={FULFILLMENT_METHODS.BOPIS}
        />,
      };
    }
    if (isBOSSDominant(bossFulfillment, localStoreLocation, quantity)) {
      return {
        method: FULFILLMENT_METHODS.BOSS,
        enabled: true,
        quantityLimitExceed: bossLocation?.inventory?.quantity > 0
          ? bossLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultBoSS fulfillment={bossFulfillment} isSpecialOrder={isSpecialOrder} configuratorHideQuantity={configuratorHideQuantity} />,
        subTemplate: <DefaultBoSSDetails
          quantity={quantity}
          fulfillment={bossFulfillment}
          isCartTiles={isCartTiles}
        />
      };
    }
    return {
      method,
      enabled: true,
      quantityLimitExceed: localStoreLocation?.inventory?.quantity > 0
        ? localStoreLocation.inventory.quantity < quantity
        : false,
      template: <DefaultBOPIS
        localStoreLocation={localStoreLocation}
        pickupStoreHours={pickupStoreHours}
        covidEnabled={covidEnabled}
        isCartTiles={isCartTiles}
        fallbackMode={bopisFulfillment?.fallbackMode || false}
        configuratorHideQuantity={configuratorHideQuantity}
      />,
      subTemplate: <DefaultBOPISDetails
        itemId={itemId}
        originalConfigId={originalConfigId}
        fulfillment={bopisFulfillment}
        isShipToHomeEligible={!!shippingFulfillment}
        quantity={quantity}
        channel={channel}
        bossFulfillment={bossFulfillment}
        pricing={pricing}
        bopisMinimumThreshold={bopisMinimumThreshold}
        bopisMinimumThresholdStores={bopisMinimumThresholdStores}
        isCartTiles={isCartTiles}
        addOnsAssemblyAndMore={addOnsAssemblyAndMore}
      />
    };
  }
  if (bossFulfillment) {
    const localStoreLocation = getLocalStore(bossFulfillment);
    if (isLocationInExcludedStates(localStoreLocation?.state, fulfillment.bossExcludedShipStates)) {
      return {
        method,
        enabled: false,
        excluded: true,
        template: <OffshoreBossUnavailable
          itemId={itemId}
          isShipToHomeEligible={!!shippingFulfillment}
          stateName={getExcludedStateName(localStoreLocation?.state)}
        />,
      };
    }
    if (isBOSSBackordered(fulfillment)) {
      return {
        method,
        enabled: true,
        quantityLimitExceed: bossLocation?.inventory?.quantity > 0
          ? bossLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultBoSS
          fulfillment={bossFulfillment}
          backordered={fulfillment?.backordered}
          backorderedShipDate={fulfillment?.backorderedShipDate}
          isSpecialOrder={isSpecialOrder}
          configuratorHideQuantity={configuratorHideQuantity}
        />,
        subTemplate: <DefaultBoSSDetails
          quantity={quantity}
          backordered={fulfillment?.backordered}
          fulfillment={bossFulfillment}
          isCartTiles={isCartTiles}
        />
      };
    }
    if (type === 'Shared' && isBOSSOutOfStock(bossFulfillment)) {
      return {
        method,
        enabled: false,
        unavailable: true,
        template: <BOSSOutOfStock />,
      };
    }
    if (!isBOSSOutOfStock(bossFulfillment)) {
      return {
        method: FULFILLMENT_METHODS.BOSS,
        enabled: true,
        quantityLimitExceed: bossLocation?.inventory?.quantity > 0
          ? bossLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultBoSS fulfillment={bossFulfillment} isSpecialOrder={isSpecialOrder} configuratorHideQuantity={configuratorHideQuantity} />,
        subTemplate: <DefaultBoSSDetails
          quantity={quantity}
          fulfillment={bossFulfillment}
          isCartTiles={isCartTiles}
        />
      };
    }
  }

  return {
    method: (type === 'Store Only') ? FULFILLMENT_METHODS.STORE : FULFILLMENT_METHODS.BOPIS,
    enabled: false,
    unavailable: true,
    template: <NotAvailable
      itemId={itemId}
      type={(type === 'Store Only') ? FULFILLMENT_METHODS.STORE : FULFILLMENT_METHODS.BOPIS}
    />
  };
};
