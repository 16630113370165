import React, {
  useState, useEffect, useContext, useRef, Suspense
} from 'react';
import { useStoreId, ExperienceContext } from '@thd-nucleus/experience-context';
import './bundling-product-tabs.scss';
import { useDataModel, QueryProvider } from '@thd-nucleus/data-sources';
import { string, arrayOf, shape } from 'prop-types';
import { Carousel } from '@thd-olt-component-react/carousel';
import { BundlingTab } from './BundlingTab';
import { SelectedItemDetailsWrapper } from './SelectedItemDetailsWrapper';
import { SelectedItemDetails } from './SelectedItemDetails';
import { dataModel } from '../dataModel';
import { QuantityAdjustmentsContext } from '../QuantityAdjustmentsContext';
import { FulfillmentLazy } from './FulfillmentLazy';

const BundlingProductTabs = ({ itemIds = [], products = [], dataSource, packageCategory }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundling-product-tabs.ready'); }, []);
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const isGM = packageCategory === 'gm';
  const storeId = useStoreId();
  const storeIdRef = useRef(storeId);
  const { data, loading, error, variables } = useDataModel('products', {
    variables: {
      itemIds,
      storeId
    },
    skip: !!products.length || !itemIds.length
  });

  const { itemMap, setItemMap } = useContext(QuantityAdjustmentsContext);

  useEffect(() => {
    if (data?.products) {
      setItemMap(data.products.reduce((map, product) => {
        if (product?.itemId) {
          return {
            ...map,
            [product.itemId]: { ...map[product.itemId], selectedQty: 1 }
          };
        }
        return map;
      }, itemMap));
    }
  }, [data]);

  const bundleProducts = products?.length > 0 ? products : data?.products || [];

  const [selectedProductId, setSelectedProductId] = useState(bundleProducts[0]?.itemId || '');
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [isTabOpen, setTabOpen] = useState(!isMobile);

  useEffect(() => {
    setSelectedProductId(bundleProducts[0]?.itemId || '');
  }, [bundleProducts]);

  const selectedProduct = bundleProducts.find((item) => item.itemId === selectedProductId);

  if (variables?.storeId && data && !loading && !error) {
    storeIdRef.current = variables.storeId;
  }

  const handleClick = (productId, index) => {
    if ((!isTabOpen || productId === selectedProductId) && isMobile) {
      setTabOpen((prevState) => !prevState);
    }

    setSelectedProductId(productId);
    setSelectedProductIndex(index);

    if (storeIdRef.current !== storeId) {
      storeIdRef.current = storeId;
    }
  };

  const bundlingArray = bundleProducts.map((bundleProduct, index) => (
    <BundlingTab
      key={bundleProduct.itemId}
      itemId={bundleProduct.itemId}
      handleClick={() => handleClick(bundleProduct.itemId, index)}
      isSelected={bundleProduct.itemId === selectedProductId}
      isTabOpen={isTabOpen}
      data={bundleProduct}
    />
  ));

  const [mountFulfillment, setMountFulfillment] = useState(!isMobile);

  const loadFulfillment = (event) => {
    event.preventDefault();
    if (!mountFulfillment) {
      setMountFulfillment(true);
    }
  };

  const sharedProps = {
    storeId: storeIdRef.current,
    itemId: selectedProductId,
    data: selectedProduct
  };

  return (
    <div
      className="bundling-product-tabs"
      data-testid="bundling-product-tabs"
      data-component="BundlingProductTabs"
      onMouseEnter={loadFulfillment}
    >
      {!isMobile ? (
        <Carousel
          itemClass="fixed-item"
          fixedItem
          multiItem={false}
          itemWidthPixelsDesktop={180}
        >
          {bundlingArray}
        </Carousel>
      )
        : (
          <div className="bundling-product-tabs__selectors">
            {bundlingArray}
          </div>
        )}
      {isTabOpen || !isMobile ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SelectedItemDetailsWrapper {...sharedProps} dataSource={dataSource}>
          <SelectedItemDetails
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sharedProps}
            channel={channel}
            displayMobile={isMobile}
            packageCategory={packageCategory}
            mountFulfillment={mountFulfillment}
            selectedProductIndex={selectedProductIndex}
          />
        </SelectedItemDetailsWrapper>
      ) : null}
      {typeof window !== 'undefined' && isGM && isMobile && (
        <Suspense fallback={<div />}>
          <QueryProvider
            dataSource="catalog"
            cacheKey="selected-item-details-fulfillment"
          >
            <FulfillmentLazy disableUI />
          </QueryProvider>
        </Suspense>
      )}
    </div>
  );
};

BundlingProductTabs.dataModel = dataModel;

BundlingProductTabs.displayName = 'BundlingProductTabs';

BundlingProductTabs.propTypes = {
  itemIds: arrayOf(string),
  products: arrayOf(shape),
  dataSource: string,
  packageCategory: string
};

BundlingProductTabs.defaultProps = {
  itemIds: [],
  products: [],
  dataSource: 'catalog',
  packageCategory: ''
};

export { BundlingProductTabs };
