import { BOGO, SPECIAL_BUYS } from '../constants';

export const getExperienceTag = (promotion) => {
  return promotion?.experienceTag;
};

export const getSubExperienceTag = (promotion) => {
  return promotion?.subExperienceTag;
};

export const getShortDescription = (promotion) => {
  return promotion?.description?.shortDesc;
};

export const getLongDescription = (promotion) => {
  return promotion?.description?.longDesc;
};

export const getStartDate = (promotion) => {
  return promotion?.dates?.start;
};

export const getEndDate = (promotion) => {
  return promotion?.dates?.end;
};

const hasShortDescription = (promotion) => {
  return !!getShortDescription(promotion);
};

export const getExpTagPromo = (data) => {
  return data?.product?.pricing?.conditionalPromotions?.[0];
};

export const getNonExpTagPromo = (data) => {
  return data?.product?.pricing?.conditionalPromotions?.[1];
};

export const getDiscountPromo = (data) => {
  return data?.product?.pricing?.promotion;
};

export const getSpecialBuy = (promotion) => {
  const specialBuyKey = getShortDescription(promotion);
  return SPECIAL_BUYS[specialBuyKey];
};

export const hasExpTagPromo = (data) => {
  const expTagPromo = getExpTagPromo(data);
  return hasShortDescription(expTagPromo);
};

export const hasNonExpTagPromo = (data) => {
  const nonExpTagPromo = getNonExpTagPromo(data);
  return hasShortDescription(nonExpTagPromo);
};

export const hasDiscountPromo = (data) => {
  const discountPromo = getDiscountPromo(data);
  return hasShortDescription(discountPromo);
};

export const hasDiscountMatchingConditional = (data) => {
  const discountPromo = getDiscountPromo(data);
  const expTagPromo = getExpTagPromo(data);
  const discountExperienceTag = getExperienceTag(discountPromo);
  const discountSubExperienceTag = getSubExperienceTag(discountPromo);
  const conditionalExperienceTag = getExperienceTag(expTagPromo);
  const conditionalSubExperienceTag = getSubExperienceTag(expTagPromo);

  return (discountExperienceTag === conditionalExperienceTag
    && discountSubExperienceTag === conditionalSubExperienceTag);
};

export const hasPreferredPrice = (data) => {
  return !!(data?.product?.pricing?.preferredPriceFlag);
};

export const hasSpecialBuy = (promotion) => {
  const specialBuyKey = getShortDescription(promotion);
  return Object.keys(SPECIAL_BUYS).includes(specialBuyKey);
};

export const isInHiddenPromos = ({
  data, hiddenPromos
}) => {
  const expTagPromo = getExpTagPromo(data);
  const experienceTag = getExperienceTag(expTagPromo);
  const subExperienceTag = getSubExperienceTag(expTagPromo);

  return hiddenPromos.some((hiddenPromo) => {
    if (experienceTag === BOGO) {
      return (hiddenPromo === subExperienceTag);
    }
    return (hiddenPromo === experienceTag);
  });
};

/*
  Business priority logic for selecting the short description to display:
  0. preferred pricing (return null)
  1. Special Buy of the Day/Week
  2. unhidden conditional promos that have short descs
  3. any other PEMS promo with short description
    a. ex: Coupons or other PE promos with short descriptions
  4. any remaining promo short desc such as from pacman
*/
export const getPromoToDisplay = ({ data, hiddenPromos, hideConditionalPromoDescription }) => {
  const isPreferredPrice = hasPreferredPrice(data);
  const isSpecialBuy = hasSpecialBuy(getDiscountPromo(data));
  const isExpTagPromotion = hasExpTagPromo(data);
  const isNonExpTagPromotion = hasNonExpTagPromo(data);
  const isHiddenConditionalPromo = hideConditionalPromoDescription && isInHiddenPromos({ data, hiddenPromos });
  const isHiddenDiscountPromo = isHiddenConditionalPromo && hasDiscountMatchingConditional(data);

  if (isPreferredPrice) return null;

  if (isSpecialBuy) return getDiscountPromo(data);

  // includes conditionalPromotions with an experienceTag
  if (isExpTagPromotion && !isHiddenConditionalPromo) {
    return getExpTagPromo(data);
  }

  if (isNonExpTagPromotion) {
    return getNonExpTagPromo(data);
  }

  // includes pacman promos and conditional promos without an experienceTag (coupons, proXtra, etc)
  if (hasDiscountPromo(data) && !isHiddenDiscountPromo) {
    return getDiscountPromo(data);
  }

  return null;
};
