import React, { useEffect, useContext } from 'react';
import { Button } from '@thd-olt-component-react/core-ui';
import PropTypes from 'prop-types';
import {
  alias, params, shape, string, bool, useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import classNames from 'classnames/bind';
import styles from './roomVo.module.scss';

const cx = classNames.bind(styles);

export const RoomVo = (props) => {
  const ROOMVO_SCRIPT_SRC = 'https://cdn.roomvo.com/static/scripts/b2b/homedepot.js';
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const { itemId, queryVariables } = props;

  const variables = queryVariables || {
    itemId
  };

  const { data } = useDataModel('clientOnlyProduct', {
    skip: !variables.itemId,
    ssr: false,
    variables
  });

  useEffect(() => {
    let loadedRoomVoScript = {};
    if (data?.product?.identifiers?.roomVOEnabled) {
      let scripts = [];
      if (typeof window !== 'undefined' && window.document) {
        scripts = Array.from(document.getElementsByTagName('script'));
      }
      loadedRoomVoScript = scripts.find((script) => script.getAttribute('src') === ROOMVO_SCRIPT_SRC);

      if (!loadedRoomVoScript && itemId) {
        const script = document.createElement('script');
        script.src = ROOMVO_SCRIPT_SRC;
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);
      }
    }

    return () => {
      if (loadedRoomVoScript?.parentNode) {
        loadedRoomVoScript.parentNode.removeChild(loadedRoomVoScript);
      }
    };
  }, [data?.product?.identifiers?.roomVOEnabled]);

  if (!variables.itemId || !data?.product?.identifiers?.roomVOEnabled) return null;

  const SUB_HEADING = 'See This in My Room';
  const VIRTUAL_SAMPLE_SUB_HEADING = 'View Virtual Sample';

  return (
    /* Note: roomvo-container div & roomvo-stimr class are added as per the requirements from the roomvo 3rd party
      and should not be removed. */
    <div className={cx(isMobile ? 'roomvo-mobile-container' : 'roomvo-desktop-container')} data-component="RoomVo">
      <div className={cx('roomvo-container', 'roomvo-content')}>
        <div className={cx('roomvo-wrapper')}>
          <Button
            className={cx('roomvo', 'roomvo-stimr')}
            contentClass={cx('roomvo-bttn')}
            data-sku={itemId}
            outline
            dark
          >
            <div className={cx('roomvo__sub-heading')}>{SUB_HEADING}</div>
          </Button>
        </div>
      </div>
      <div className={cx('roomvo-container_3d', 'roomvo-content')}>
        <div className={cx('roomvo-wrapper')}>
          <Button
            className={cx('roomvo', 'roomvo-stimr', 'roomvo-product-display-button', 'roomvo-virtual-btn')}
            contentClass={cx('roomvo-bttn')}
            data-sku={itemId}
            outline
            dark
          >
            <div className={cx('roomvo__sub-heading')}>{VIRTUAL_SAMPLE_SUB_HEADING}</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

RoomVo.displayName = 'RoomVo';

RoomVo.propTypes = {
  itemId: PropTypes.string.isRequired,
  /**
   * Override for query variables onto the clientOnlyProduct query.
   * Allows you to normalize the query variables in order to ensure only one clientOnlyProduct query.
   */
  queryVariables: PropTypes.shape({
    itemId: PropTypes.string,
    storeId: PropTypes.string
  })
};

RoomVo.defaultProps = {
  queryVariables: undefined
};

RoomVo.dataModel = {
  clientOnlyProduct: alias('product').params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSource: string(),
    identifiers: shape({
      roomVOEnabled: bool()
    })
  })
};
