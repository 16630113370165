import React from 'react';
import { string } from 'prop-types';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components'
import { isDollarThresholdBogo as isDollarThresholdBogoFn } from '../../../utils/promo-utils';
import { EXPERIENCE_TAGS } from '../../../utils/constants';

export const PodCtaLoader = ({ experienceTag, subExperienceTag }) => {
  const numRows = {
    [EXPERIENCE_TAGS.BOGO]: isDollarThresholdBogoFn({ subExperienceTag }) ? 4 : 9,
    [EXPERIENCE_TAGS.MSB]: 8,
    [EXPERIENCE_TAGS.BMSM]: 8,
  };

  const rows = new Array(numRows[experienceTag] || 5).fill('');

  return (
    <div
      data-component="PromotionProductsPodCtaLoader"
      className="PromotionProductsPodCtaLoader"
    >
      <Skeleton grow style={{ width: '100%' }}>
        {rows.map((value, i) => (
          <SkeletonBlock
            key={`row-${i}`}
            disablePadding
            aspect="wide"
            height={2}
            width="100%"
            density="normal"
            style={{ margin: '-7px 0' }}
          />
        ))}
      </Skeleton>
    </div>
  );
};

PodCtaLoader.displayName = 'PodCtaLoader';

PodCtaLoader.propTypes = {
  experienceTag: string,
  subExperienceTag: string,
};

PodCtaLoader.defaultProps = {
  experienceTag: null,
  subExperienceTag: null,
};
