// eslint-disable-next-line max-len
import { useDataModel } from '@thd-nucleus/data-sources';
import { applicableDelivery, applicablePickUp } from '../utils/product-add-on-utils';

const MAINTENANCE_SERVICE_TYPE = 'Maintenance';

const hasInstallationData = (data) => {
  const { installSkus = [] } = data?.serviceAddOns?.attachLabor || {};
  const { serviceType = '', installRetailPrice = {} } = (installSkus?.length && installSkus?.[0]) || {};
  return serviceType && serviceType !== MAINTENANCE_SERVICE_TYPE && installRetailPrice?.price;
};

const parseEligibleSkus = (str) => {
  return !str ? [] : str.split(',');
};

const useProductAddOnsFlow = ({ itemId, storeId, zipCode }) => {
  let optionsForProductVar = {
    itemId,
    storeId,
  };
  if (zipCode) {
    optionsForProductVar = {
      ...optionsForProductVar,
      zipCode
    };
  }
  const optionsForProduct = {
    variables: optionsForProductVar,
    ssr: false,
  };
  const { data: productData, loading: pLoading } = useDataModel('clientOnlyProduct', optionsForProduct);

  const {
    protectionPlanSku = false,
    hasServiceAddOns = false,
    consultationType,
    eligibleProtectionPlanSkus = false
  } = productData?.product?.info || {};

  let eligibleProtectionPlanSkusArr = parseEligibleSkus(eligibleProtectionPlanSkus);
  if (eligibleProtectionPlanSkusArr.includes('String')) {
    let index = eligibleProtectionPlanSkusArr.indexOf('String');
    if (index !== -1) {
      eligibleProtectionPlanSkusArr.splice(index, 1);
    }
  }
  let protectionPlanSkus = [];
  let skipvalue = true;
  if (eligibleProtectionPlanSkusArr.length > 0) {
    protectionPlanSkus = eligibleProtectionPlanSkusArr;
    skipvalue = false;
  } else if (protectionPlanSku) {
    protectionPlanSkus = parseEligibleSkus(protectionPlanSku);
    skipvalue = false;
    let index = protectionPlanSkus.indexOf('String');
    if (index !== -1) {
      protectionPlanSkus.splice(index, 1);
      skipvalue = true;
    }
  }

  const inStoreAssemblyAvailable = productData?.product?.fulfillment?.inStoreAssemblyEligible;
  const bodfsAssemblyEligible = productData?.product?.fulfillment?.bodfsAssemblyEligible;
  const applicableFulfillments = [
    ...applicablePickUp(productData?.product?.fulfillment),
    ...applicableDelivery(productData?.product?.fulfillment)
  ];

  const { value } = productData?.product?.pricing || {};
  const { discontinued } = productData?.product?.availabilityType || {};

  let optionsForProtectionPlan = {
    variables: {
      storeId,
      price: value || 0.00,
    },
    ssr: false,
    skip: skipvalue,
    protectionPlanSkuArr: protectionPlanSkus,
  };

  const optionsForServiceAddOns = {
    variables: {
      itemId,
      storeId,
    },
    ssr: false,
    skip: !(hasServiceAddOns || consultationType),
  };

  const { data: serviceAddOnsData, loading: sLoading } = useDataModel('serviceAddOns', optionsForServiceAddOns);

  const {
    virtualAssistanceFlag = false
  } = serviceAddOnsData?.serviceAddOns?.consultationDetails || {};

  const isSAMInstallCategory = (
    serviceAddOnsData?.serviceAddOns?.sellFurnishInstall?.installationProgramCategory === 'Hard Surface Flooring'
  );
  const isInstallationSku = !discontinued && !!hasInstallationData(serviceAddOnsData);
  const isAssembly = !discontinued && !!serviceAddOnsData?.serviceAddOns?.proReferrals?.[0]?.assemblyCategory;
  const isProReferral = !discontinued
    && !!serviceAddOnsData?.serviceAddOns?.proReferrals?.[0]?.available
    && !isAssembly;
  const isDualPath = !discontinued && !!serviceAddOnsData?.serviceAddOns?.sellFurnishInstall?.dualPathSku;
  const isSAMDualPath = !discontinued && isSAMInstallCategory;
  const isVirtualAssistance = consultationType && virtualAssistanceFlag;
  const loading = pLoading || sLoading;
  const data = {
    ...productData,
    ...serviceAddOnsData
  };

  return {
    loading,
    data,
    optionsForProtectionPlan,
    isProReferral,
    isAssembly,
    isDualPath,
    isInstallationSku,
    inStoreAssemblyAvailable,
    bodfsAssemblyEligible,
    isSAMDualPath,
    isVirtualAssistance,
    discontinued,
    applicableFulfillments
  };
};

export { useProductAddOnsFlow };
