import React, { useState, useEffect, useContext } from 'react';
import { Col } from '@thd-olt-component-react/core-ui';
import { CheckAvailabilityContext } from '../../../CheckAvailabilityContext';
import { AvailabilityMessage } from '../depotDirectResponse/AvailabilityMessage';
import {
  AVAILABLE,
  BACK_ORDERED
} from '../../../helpers/constants';

export const ResponseOnly = () => {
  const {
    itemId,
    queryIds
  } = useContext(CheckAvailabilityContext);
  const [availabilityData, setAvailabilityData] = useState();
  const [statusToUse, setStatusToUse] = useState();
  useEffect(() => {
    if (queryIds) {
      LIFE_CYCLE_EVENT_BUS.on('check-availability.depot-direct-success', (eventData) => {
        if (queryIds.join() !== eventData.output.stringifiedQueryId) return null;
        const { products } = eventData?.output?.processedData;
        const matchedItem = products?.find((item) => item.itemId === itemId);
        if (matchedItem) {
          setAvailabilityData(eventData.output.processedData);
          if (matchedItem.status === AVAILABLE) {
            setStatusToUse(eventData.output.processedData.hasBackordered ? BACK_ORDERED : matchedItem.status);
          } else {
            setStatusToUse(matchedItem.status);
          }
        }
        return null;
      });
    }
  }, [queryIds]);

  if (!availabilityData || !statusToUse) return null;

  return (
    <Col nopadding>
      <AvailabilityMessage
        availabilityData={availabilityData}
        statusToUse={statusToUse}
      />
    </Col>
  );
};
