export const FULFILLMENT_LOCATION_STH = 'DirectShip';
export const FULFILLMENT_METHOD_STH = 'ShipToHome';
export const FULFILLMENT_METHOD_BOSS = 'ShipToStore';
export const FULFILLMENT_METHOD_BOPIS = 'BOPIS';
export const FULFILLMENT_METHOD_BODFS = 'DeliverFromStore';
export const FULFILLMENT_METHOD_APPLIANCE = 'DirectDelivery';
export const DETAILS_URL = 'https://www.homedepot.com/c/The_Home_Depot_Protection_Plans';

export const ICONS = {
  tool: 'use-and-care-manual.svg',
  info: 'info.svg',
  proReferralIcon: 'pro-referral-svg.svg',
  proReferralIconNoText: 'pro-referral-no-text.svg',
  hdpp: 'hdpp-allstate.svg',
};
export const STATIC_ASSETS_PATH = 'https://assets.homedepot-static.com/images/v1/';

export const cartBinding = {
  ATTACH_LABOR: 'attach-labor',
  ATTACH_LABOR_STORE_ASSEMBLY: 'assembleItemAtStore',
  HDPP: 'hdpp',
  PRO_REFERRAL: 'pro-referral',
  PRO_ASSEMBLY: 'assembly',
  DUAL_PATH: 'dual-path',
  SAM_DUAL_PATH: 'sam-dual-path',
  BODFS_ASSEMBLY: 'bodfsAssembly'
};

// ATTACH LABOR CONSTANTS

export const attachLaborType = {
  ASSEMBLY: 'assembly',
  INSTALL: 'install'
};

// DUAL PATH CONTACT INFORMATION

export const dualPathContactInformation = {
  TANK_WATER_HEATER: {
    label: '1-855-400-2552',
    tel: '18554002552'
  },
  WATER_HEATER_REPAIR: {
    label: '1-855-400-2552',
    tel: '18554002552'
  },
  PLUMBING_REPAIR: {
    label: '1-855-466-3337',
    tel: '18554663337'
  }
};

export const virtualAssistanceContactInformation = {
  help_contact: {
    label: '1-855-400-2552',
    tel: '18554002552'
  },
  help_fast_contact: {
    label: '1-833-432-7766',
    tel: '18334327766'
  },
};

export const getFulfillmentByAddOn = (addOnType) => {
  switch (addOnType) {
  case 'attach-labor':
    return FULFILLMENT_METHOD_STH;
  case 'assembleItemAtStore':
    return FULFILLMENT_METHOD_BOPIS;
  default:
    return null;
  }
};