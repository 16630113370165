import React from 'react';
import {
  string, array
} from 'prop-types';
import { dynamicRecsDataModel } from '../dataModel';
import { DynamicRecsWrapper } from '../dynamic-recs/DynamicRecsWrapper';

const ItemRelatedGroups = (props) => {

  const { IRGSchemaObj, anchorId } = props;

  if (!IRGSchemaObj.length || IRGSchemaObj[0] === undefined) {
    return null;
  }

  return (
    <>
      {(IRGSchemaObj && IRGSchemaObj.length > 0 && IRGSchemaObj?.map((schemaName, key) => {
        return (
          <DynamicRecsWrapper
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            key={key}
            isCategoryTab={schemaName !== 'irgaccessories'}
            schemaName={schemaName}
            anchorId={anchorId}
          />
        );
      })
      )}
    </>
  );
};
ItemRelatedGroups.displayName = 'ItemRelatedGroups';

const propTypes = {
  anchorId: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  IRGSchemaObj: array
};
const defaultProps = {
  IRGSchemaObj: null
};
const dataModel = dynamicRecsDataModel;

ItemRelatedGroups.propTypes = propTypes;
ItemRelatedGroups.defaultProps = defaultProps;
ItemRelatedGroups.dataModel = dataModel;

export default ItemRelatedGroups;