import React from 'react';
import PropTypes from 'prop-types';
import '../styles/zipInputModal.scss';
import { Overlay } from '@thd-olt-component-react/core-ui';
import { InputForm } from './subcomponents/inputForm/InputForm';
import {
  DELIVERY_ZIP_BODY,
  DELIVERY_ZIP_HEADER,
  DELIVERY_ZIP_INPUT
} from '../helpers/constants';

export const ZipInputModal = ({ onSubmit, errorMessage, overlay, setOverlay }) => (
  <Overlay
    onClose={() => setOverlay(false)}
    open={overlay}
    closeButton
    className="ZipInputModal__overlay"
  >
    <div className="ZipInputModal__wrapper" data-component="ZipInputModal">
      <span className="ZipInputModal__header">{DELIVERY_ZIP_HEADER}</span>
      <div className="ZipInputModal__divider" />
      <span className="ZipInputModal__body">
        {DELIVERY_ZIP_BODY}
      </span>
      <div className="check-availability">
        <InputForm onSubmit={onSubmit} errorMessage={errorMessage} placeholderText={DELIVERY_ZIP_INPUT} />
      </div>
    </div>
  </Overlay>
);

ZipInputModal.propTypes = {
  onSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  overlay: PropTypes.bool,
  setOverlay: PropTypes.func,
};

ZipInputModal.defaultProps = {
  onSubmit: () => {},
  errorMessage: null,
  overlay: null,
  setOverlay: () => {},
};
