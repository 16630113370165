import React, { createContext, useEffect, useState } from 'react';
import { bool, node, string } from 'prop-types';
import { determineZipCode } from './helpers/utils';

export const CheckAvailabilityContext = createContext({});

export const CheckAvailabilityProvider = (props) => {
  const {
    isMajorApplianceItemOrItems,
    zipCode,
    showCheckAvailability,
    useDrawerModal,
    children,
  } = props;

  useEffect(() => LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('check-availability.ready'), []);

  const [zip, setZip] = useState(determineZipCode(zipCode));

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('NATIVE_APP.DELIVERY_ZIP', ({ output }) => {
      if (output?.deliveryZip) {
        setZip(output?.deliveryZip);
      }
    });
  }, []);

  const contextValue = {
    isMajorApplianceItemOrItems,
    setZip,
    zip,
    showCheckAvailability,
    useDrawerModal,
    ...props
  };

  return (
    <CheckAvailabilityContext.Provider value={contextValue}>
      {children}
    </CheckAvailabilityContext.Provider>
  );
};

CheckAvailabilityProvider.propTypes = {
  isMajorApplianceItemOrItems: bool,
  zipCode: string,
  children: node,
  useDrawerModal: bool,
  showCheckAvailability: bool,
};

CheckAvailabilityProvider.defaultProps = {
  isMajorApplianceItemOrItems: null,
  zipCode: '',
  children: null,
  useDrawerModal: null,
  showCheckAvailability: null,
};
