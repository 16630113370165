import {
  arrayOf,
  bool,
  number,
  shape,
  string,

} from '@thd-nucleus/data-sources/react/dataModel/prop-types';
import { params, alias } from '@thd-nucleus/data-sources';

const Service = shape({
  deliveryTimeline: string(),
  deliveryDates: shape({
    startDate: string(),
    endDate: string(),
  }),
  deliveryCharge: string(),
  dynamicEta: shape({
    hours: string(),
    minutes: string()
  }),
  freeDeliveryThreshold: number({ float: true }),
  hasFreeShipping: bool(),
  locations: arrayOf(shape({
    distance: number({ float: true }),
    inventory: shape({
      isOutOfStock: bool(),
      isInStock: bool(),
      isLimitedQuantity: bool(),
      isUnavailable: bool(),
      quantity: number(),
    }),
    isAnchor: bool(),
    locationId: string(),
    storeName: string(),
    storePhone: string(),
    type: string(),
  })),
  type: string(),
  totalCharge: number({ float: true }),
  mode: shape({
    code: string(),
    desc: string(),
    group: string(),
    longDesc: string()
  })
});

export const dataModel = {
  // plural, we dont want to conflict with the others
  clientOnlyProducts: alias('products')
    .params({
      itemIds: arrayOf(string().isRequired()).isRequired()
    }).arrayOf(shape({
      identifiers: shape({
        productType: string()
      }),
      itemId: string()
    })),
  shipping: params({
    itemId: string().isRequired(),
    storeId: string(),
    zipCode: string(),
    quantity: number().isRequired(),
    price: number({ float: true }).isRequired()
  }).shape({
    itemId: string(),
    state: string(),
    excludedShipStates: string(),
    zipCode: string(),
    services: arrayOf(Service)
  })
};
