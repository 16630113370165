import isDraftProduct from "./isDraftProduct";

export const findOmsId = () => {
  const url = typeof window !== 'undefined' ? window?.location?.href : '';
  let id;

  if (isDraftProduct) {
    id = url.match(/\?draftProductId=([0-9]+)\&?/i)[1];
  } else {
    const urlOmsIdMatches = url.match(/\/([0-9]{9})/);
    const anchorSkuMatches = url.match(/\?omsid=([0-9]+)\&?/i);
    id = anchorSkuMatches ? anchorSkuMatches[1] : (urlOmsIdMatches ? urlOmsIdMatches[1] : null);
  }

  return id;
}


const omsId = findOmsId();

export default omsId;
