import React, { useState } from 'react';
import { string, oneOf, func } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Assemble } from '@one-thd/sui-icons';
import { ScheduleAMeasureCta } from '@thd-olt-component-react/home-services-cta-buttons';
import { SAMDualPathTooltip } from './SAMDualPathTooltip';
import { ProductAddOnsPodStencil } from '../../common/product-add-ons-pod';

const SAMDualPath = ({
  channel,
  itemId,
  onChange,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (evt) => {
    LIFE_CYCLE_EVENT_BUS.trigger('SAM-dual-path.change', { isOptedIn: !checked, itemId });
    onChange({
      itemId,
      checked: evt.target.checked,
      addOnType: evt.target.value
    });
    setChecked(!checked);
  };

  return (
    <>
      <ProductAddOnsPodStencil
        channel={channel}
        heading="Get Expert In-Home Installation"
        subHeading="Our licensed and insured installer will take care of the job for you. "
        icon={<Assemble size="large" />}
        onChange={handleChange}
        tooltip={(
          <SAMDualPathTooltip
            channel={channel}
            checked={checked}
          />
        )}
        userOptions={[
          {
            id: 'item ID',
            value: 'SAM-dual-path',
            checked,
            label: 'Add In-Home Installation'
          }
        ]}
      >
        <>
          <div className={`${!checked && 'u--hide'}`}>
            <QueryProvider cacheKey="schedule-a-measure">
              <ScheduleAMeasureCta itemId={itemId} hideContactInfo />
            </QueryProvider>
          </div>
        </>
      </ProductAddOnsPodStencil>
    </>
  );
};

SAMDualPath.displayName = 'ProductAddOns-SAMDualPath';

SAMDualPath.propTypes = {
  /** What view to render ['desktop' || 'mobile']  */
  channel: oneOf(['desktop', 'mobile']),
  itemId: string.isRequired,
  onChange: func.isRequired,
};

SAMDualPath.defaultProps = {
  channel: 'desktop',
};

export { SAMDualPath };