import React, { useContext, useState, useEffect, useRef } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { CheckAvailabilityContext } from '../CheckAvailabilityContext';
import '../styles/check-availability.scss';
import './subcomponents/inputForm/input-form.scss';
import '../styles/pickup-drawer.scss';

export const PickupDrawerInputForm = () => {
  const {
    inputHeaderTitle,
    onFormSubmit
  } = useContext(CheckAvailabilityContext);

  const { channel } = useContext(ExperienceContext);

  const [input, setInput] = useState('');

  const onInputChange = (event) => {
    const sanitizeInput = event?.target?.value?.replace(/[^A-Za-z0-9, ]/, '');
    setInput(sanitizeInput);
  };

  const searchBarRef = useRef(null);
  const [isSearchBarClicked, setSearchBarIsClicked] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (channel === 'mobile' && searchBarRef?.current && !isFirstRender?.current) {
      searchBarRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    isFirstRender.current = false;
  }, [isSearchBarClicked]);

  return (
    <div className="input-form pickup-drawer">
      { inputHeaderTitle
            && (
              <div className="pickup-drawer__title">
                <b>{inputHeaderTitle}</b>
              </div>
            )}
      <div>
        <form
          id="zip-code-availability-form"
          onSubmit={(event) => {
            event.preventDefault(); // Stops page refresh
            if (onFormSubmit) onFormSubmit(input);
          }}
        >
          <div className="pickup-drawer__input-bar__width-height">
            <span className="form-input">
              <input
                ref={searchBarRef}
                type="search"
                placeholder="Zip Code, City, State, or Store Number"
                maxLength="25"
                className="form-input__field pickup-drawer__input-bar"
                id="check-availability__zip-code"
                name="check-availability__zip-code"
                autoComplete="off"
                onChange={onInputChange}
                onClick={() => setSearchBarIsClicked(!isSearchBarClicked)}
                value={input}
              />
            </span>
          </div>
          <div className="pickup-drawer__search-btn__width">
            <button
              type="submit"
              id="pickup-drawer-formButton"
              className="bttn input-group--inline__bttn pickup-drawer__search-btn"
            >
              <span className="bttn__content pickup-drawer__search-btn">
                <img
                  className="pickup-drawer__magnify-img"
                  width="30px"
                  height="30px"
                  alt="Search"
                  src="https://assets.thdstatic.com/images/v1/magnify-symbol-white.svg"
                />
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

PickupDrawerInputForm.propTypes = {};
