import { useConfigService } from '@thd-nucleus/experience-context';

import { getIsFeatureEnabled } from '../utils/promo-utils';
import { FEATURE_SWITCH_KEYS } from '../utils/constants';

/**
 * @param {boolean | null} [props.bogoEnabled]
 * Enables BOGO promotions. The config service can enable or disable as well. Default: `true`
 * @param {boolean | null} [props.msbEnabled]
 * Enables MSB promotions. The config service can enable or disable as well. Default: `true`
 * @param {boolean | null} [props.bmsmEnabled]
 * Enables BMSM promotions. The config service can enable or disable as well. Default: `true`
 * @param {boolean | null} [props.backwardsBogoEnabled]
 * Enables Backwards BOGO promotions. The config service can enable or disable as well. Default: `false`
 * @param {boolean | null} [props.bogoDollarThresholdEnabled]
 * Enables BOGO Dollar Threshold promotions. The config service can enable or disable as well. Default: `false`
 * @param {boolean | null} [props.bxg1Enabled]
 * Enables Buy Two Get One promotions. The config service can enable or disable as well. Default: `false`
 *
 * @returns {*} the computed value of each feature switch
 */
export const useFeatureSwitches = ({
  bogoEnabledProp,
  msbEnabledProp,
  bmsmEnabledProp,
  backwardsBogoEnabledProp,
  bogoDollarThresholdEnabledProp,
  bxg1EnabledProp,
}) => {
  const bogoEnabled = getIsFeatureEnabled({
    defaultValue: true,
    prop: bogoEnabledProp,
    config: useConfigService(FEATURE_SWITCH_KEYS.bogo),
  });
  const msbEnabled = getIsFeatureEnabled({
    defaultValue: true,
    prop: msbEnabledProp,
    config: useConfigService(FEATURE_SWITCH_KEYS.msb),
  });
  const bmsmEnabled = getIsFeatureEnabled({
    defaultValue: true,
    prop: bmsmEnabledProp,
    config: useConfigService(FEATURE_SWITCH_KEYS.bmsm),
  });
  const backwardsBogoEnabled = getIsFeatureEnabled({
    defaultValue: false,
    prop: backwardsBogoEnabledProp,
    config: useConfigService(FEATURE_SWITCH_KEYS.backwardsBogo),
  });
  const bogoDollarThresholdEnabled = getIsFeatureEnabled({
    defaultValue: false,
    prop: bogoDollarThresholdEnabledProp,
    config: useConfigService(FEATURE_SWITCH_KEYS.bogoDollarThreshold),
  });
  const bxg1Enabled = getIsFeatureEnabled({
    defaultValue: false,
    prop: bxg1EnabledProp,
    config: useConfigService(FEATURE_SWITCH_KEYS.bxg1Enabled),
  });

  return {
    bogoEnabled,
    msbEnabled,
    bmsmEnabled,
    backwardsBogoEnabled,
    bogoDollarThresholdEnabled,
    bxg1Enabled,
  };
};
