import {
  shape, params, string, number, bool, extend, arrayOf
} from '@thd-nucleus/data-sources';
import { BundlingProductTabs } from '@thd-olt-component-react/bundling-product-tabs';
import { ConsumerCardMessaging } from '@thd-olt-component-react/consumer-card-messaging';

const ProductModel = extend({
  itemId: string(),
  dataSources: string(),
  identifiers: shape({
    itemId: string(),
    productLabel: string(),
    productType: string()
  }),
  info: shape({
    productGroup: string(),
    label: string()
  }),
  pricing: params({ storeId: string() }).shape({
    original: number({ float: true }),
    value: number({ float: true }),
    mapAboveOriginalPrice: bool(),
    promotion: shape({
      dollarOff: number({ float: true })
    }),
    promotionalAdjustments: arrayOf(shape({
      type: string(),
      description: shape({
        shortDesc: string(),
        longDesc: string()
      }),
      dollarOff: number({ float: true }),
      percentageOff: number({ float: true }),
      promoId: string(),
    })),
  })
},
BundlingProductTabs.dataModel.product
);

const packagesMiniDataModel = extend({
  packages: params({ itemId: string(), storeId: string(), packageId: string(), limit: number() })
    .arrayOf(
      shape({
        metadata: shape({
          packageId: string(),
          title: string(),
          brandName: string(),
          brandImage: shape({ url: string() }),
          heroImage: shape({ url: string() }),
          image: shape({ url: string() }),
          name: string(),
          defaultImage: shape({
            url: string()
          }),
          packageCategory: string()
        }),
        products: params().arrayOf(shape(ProductModel))
      })
    )
},
ConsumerCardMessaging,
);

export const dataModel = packagesMiniDataModel;
